import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import useHandleAddProduct from '../../utils/handleAddProduct';
import ShowCurrencies from '../../utils/showCurrencies';

function Support({ title, imageUrl, footerText, buttonText, contributes, layout, description, listItems, product, isActive, onClickTab }) {
  const isImageRight = layout === 'right';
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();

  const [otherAmount, setOtherAmount] = useState();

  const handleAddNewProduct = () => {
    let tempProduct = product;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: otherAmount && Number(otherAmount) > 0 ? Number(otherAmount) : selectedPrice.value,
      recurring: recurring.value,
    });
    addProduct({ product: tempProduct, currency: selectedCurrencyStore });
  };

  const suggestedPrices = [
    {
      aud: 100,
      usd: 70,
      cad: 90,
      sgd: 90,
      gbp: 30,
      eur: 60,
      myr: 300,
      aed: 240,
      idr: 1002020,
    },
    {
      aud: 250,
      usd: 160,
      cad: 220,
      sgd: 220,
      gbp: 40,
      eur: 150,
      myr: 760,
      aed: 580,
      idr: 2505050,
    },
    {
      aud: 500,
      usd: 320,
      cad: 430,
      sgd: 440,
      gbp: 50,
      eur: 300,
      myr: 1510,
      aed: 1160,
      idr: 5010090,
    },
  ];

  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[0][selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : 'aud'],
    index: 0,
  });

  const suggestedRecurringModes = [
    { value: 'week', label: 'Weekly Donation' },
    { value: 'month', label: 'Monthly Donation' },
  ];
  const [recurring, setRecurring] = useState(suggestedRecurringModes[0]);

  const handleSelectedItem = (currency, index) => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    setSelectedPrice(tempSelectedPrice);
  };

  const handleOtherAmount = amount => {
    setOtherAmount(amount);
  };

  useEffect(() => {
    if (selectedCurrencyStore) {
      //   setCurrentCurrency(selectedCurrencyStore);
      handleSelectedItem(selectedCurrencyStore, selectedPrice.index);
    } else {
      //   setCurrentCurrency('AUD');
      handleSelectedItem('AUD', selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Effect to add event listener on mount and clean up on unmount
  useEffect(() => {
    const handleResize = () => {
      // Check if the window width is less than 768 pixels
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isActive) return null;

  return (
    <div>
      <div className="max-w-[1280px] mx-auto bg-[#fff] overflow-hidden" onClick={() => onClickTab()}>
        {/* Header Section */}
        <div className={`flex flex-col md:flex-row items-center ${isImageRight ? 'md:flex-row-reverse' : ''}`}>
          <div className="md:pt-10 md:w-1/3 relative xs:bg-[#14A2DC] md:bg-inherit xs:p-5 xs:pb-10 md:pb-10">
            {isMobile && description ? (
              <p className={`mt-2 mb-2 xs:text-center md:text-left ${description[0].style} text-[#fff]`}>{description[0].data}</p>
            ) : (
              ''
            )}
            <div className="bg-[#253B7E] px-5 py-7 flex flex-col gap-3">
              <div className="flex gap-1.5">
                {suggestedRecurringModes.map((item, index) => {
                  return (
                    <div value={item.value} className="w-1/2">
                      <button
                        key={`recurring${index}`}
                        className={`${
                          item.value === recurring.value ? 'bg-[#00A3DA] border border-[#00A3DA]' : 'border border-white rounded-md'
                        } text-[#fff] text-center py-3 px-1 w-full text-[16px] rounded-md font-[Branding] font-bold`}
                        onClick={() => setRecurring({ value: item.value, label: item.label })}
                      >
                        {item.label}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="flex gap-1.5">
                <div className="flex w-1/2 gap-1.5">
                  {suggestedPrices.map((item, index) => {
                    return (
                      <div key={`prices${index}`} className="w-1/3">
                        <button
                          className={`${
                            selectedPrice &&
                            selectedPrice.value === suggestedPrices[index][selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : 'aud']
                              ? 'bg-[#00A3DA] text-[#fff]'
                              : 'bg-[#fff] text-[#253B7E]'
                          }  w-full rounded-md py-1.5`}
                          onClick={() => {
                            handleSelectedItem(selectedCurrencyStore, index);
                          }}
                        >
                          {ShowCurrencies(selectedCurrencyStore, item[selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : 'aud'], false)}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div className="md:w-1/2">
                  <input
                    type="text"
                    placeholder="Other Amount"
                    className="py-1.5 rounded-md px-3 outline-none w-full"
                    value={otherAmount}
                    onChange={e => handleOtherAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="md:absolute md:inset-0 md:items-center md:justify-center pb-[22%] xs:hidden md:block">
              <button
                className="bg-[#ED145B] text-[#fff] rounded-3xl px-5 py-1 text-[20px] font-[Branding] xs:hidden md:block"
                // style={{ left: '60px', top: '177px' }}
                onClick={handleAddNewProduct}
              >
                {buttonText}
              </button>
            </div>
            <img
              src={imageUrl}
              alt="A beautiful sunset over the mountains"
              className="w-full md:w-[400px] h-auto xs:bg-[#fff] md:bg-inherit xs:p-2.5 md:p-0"
            />
            <h2 className="xs:block md:hidden text-center text-[#14A2DC] text-lg md:text-4xl font-bold pt-2 xs:bg-[#fff]">{title}</h2>
            <div className="xs:flex md:hidden justify-center bg-[#fff] pb-2">
              <span className="text-center text-[#F60362] text-[20px]">{ShowCurrencies(selectedCurrencyStore, selectedPrice.value, false)}</span>
            </div>
            <div className="bg-[#fff] xs:flex justify-center md:hidden xs:rounded-b-lg pb-3">
              <button
                className="bg-[#ED145B] text-[#fff] rounded-lg px-5 py-1 text-[20px] font-[Branding] xs:block md:hidden"
                style={{ left: '70px', top: '177px' }}
                onClick={handleAddNewProduct}
              >
                {buttonText}
              </button>
            </div>
          </div>
          <div className="p-5 md:p-10 md:w-2/3">
            <h2 className="xs:hidden md:block md:text-[#253B7E] text-lg md:text-4xl font-bold pb-5">{title}</h2>{' '}
            {description &&
              description.map((text, index) => {
                if (isMobile) {
                  // Only render the first item on mobile
                  return index !== 0 ? (
                    <p key={index} className={`mt-2 xs:text-center md:text-left ${text.style}`}>
                      {text.data}
                    </p>
                  ) : null;
                } else {
                  // Render all items on non-mobile
                  return (
                    <p key={index} className={`mt-2 xs:text-center md:text-left ${text.style}`}>
                      {text.data}
                    </p>
                  );
                }
              })}
            {listItems && (
              <ul className="mt-2 list-inside xs:text-center md:text-left xs:list-none md:list-disc">
                {listItems.map((item, index) => (
                  <li key={index} className="text mb-4">
                    <span className="font-semibold xs:block md:inline">{item.title}</span>{' '}
                    <span className="font-semibold xs:hidden md:inline">-</span> {item.description}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Contribution Section */}
        <div className="bg-[#00A3DA] py-5 px-5 xs:pb-0 md:pb-5 md:px-10">
          <h2 className="text-3xl md:text-5xl text-[#fff]  text-center font-[Gotcha] my-4">Contribute monthly or weekly to</h2>
          <div className="mt-5 flex flex-col md:flex-row justify-between">
            {contributes.map(item => {
              return (
                <div className="text-center md:w-1/4 xs:mb-5 md:mb-0">
                  <img src={item.imageUrl} alt="Orphan Feast" className="mx-auto xs:w-[220px] xs:h-[200px] md:w-[200px] md:h-[165px] rounded-2xl" />
                  <h3 className="mt-5 text-2xl md:text-lg  font-[Branding] font-extrabold	 text-[#FFFFFF]">{item.title}</h3>
                  <p className=" mt-3 text-[#FFFFFF] font-Roboto ">{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Call-to-Action Button */}
        <div className="text-center xs:pt-0 md:pt-5 py-5 bg-[#00A3DA] text-white flex flex-col justify-center items-center ">
          <p className="mb-3 xs:text-sm p-2 md:text-3xl text-center md:w-3/4 ">{footerText}</p>
          <button
            className="bg-[#ED145B] hover:#14A2DC md:w-[250px] md:h-[50px] text-white font-bold py-2 px-4 rounded-full"
            onClick={handleAddNewProduct}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Support;
