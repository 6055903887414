import { Builder } from '@builder.io/react';

import CountUp from 'react-countup';
import impactHeaderImg from "../../../../../images/general/MATW-Home-Banner-TheMATWImpact-Heading.png";
import { ImpactTotalIcon, ImpactWatertIcon, OrphanSupportIcon } from "../../../../general/customIcons";
const NewCountUp = () => {
  return <div className="flex flex-col justify-between flex-wrap col-span-12 bg-[#E1EFF2] px-2 py-8 items-center">
            <img src={impactHeaderImg} alt="Muslim Charity - MATW Project" className="w-10/12 md:w-[300px] md:h-[50px]" />
            <div className="flex flex-wrap w-full md:container md:mx-auto px-3 py-2 md:py-4 justify-center items-start">
                <div className="basis-1/3 md:basis-1/4 xl:basis-1/5 flex flex-col justify-center items-center">
                    <div className="flex text-[#ED0C6E] w-[70px] h-[70px] mb-2 bg-white rounded-full p-4 justify-center items-center">
                        <ImpactTotalIcon title={'Muslim Charity impacted over 34 million'} description={'Islamic Charity impact around the world'} />
                    </div>
                    <CountUp start={60022008} end={60022198} duration={5} separator="," decimals={0} decimal="," prefix="" suffix="" delay={0}>
                        {({
            countUpRef
          }) => <div>
                                <span className="text-[#253B7E] font-bold text-base md:text-[25px]" ref={countUpRef} />
                            </div>}
                    </CountUp>
                    <span className="text-xs md:text-base text-center text-[#78716C]">TOTAL IMPACT 2024</span>
                </div>
                <div className="basis-1/3 md:basis-1/4 xl:basis-1/5 flex flex-col justify-center items-center">
                    <div className="flex text-[#ED0C6E] w-[70px] h-[70px] mb-2 bg-white rounded-full p-4 justify-center items-center">
                        <OrphanSupportIcon title={'Muslim Charity sponsored 6,000 orphans'} description={'Islamic Charity enabling sponsorship of orphans'} />
                    </div>
                    <CountUp start={16225} end={16425} duration={5} separator="," decimals={0} decimal="," prefix="" suffix="" delay={0}>
                        {({
            countUpRef
          }) => <div>
                                <span className="text-[#253B7E] font-bold text-base md:text-[25px]" ref={countUpRef} />
                            </div>}
                    </CountUp>
                    <span className="text-xs md:text-base text-center text-[#78716C]">ORPHANS SPONSORED</span>
                </div>
                <div className="basis-1/3 md:basis-1/4 xl:basis-1/5 flex flex-col justify-center items-center">
                    <div className="flex text-[#ED0C6E] w-[70px] h-[70px] mb-2 bg-white rounded-full p-4 justify-center items-center">
                        <ImpactWatertIcon title={'Muslim Charity impact on 43,000 water wells'} description={'Islamic charity providing water wells'} />
                    </div>
                    <CountUp start={534966} end={535166} duration={5} separator="," decimals={0} decimal="," prefix="" suffix="" delay={0}>
                        {({
            countUpRef
          }) => <div>
                                <span className="text-[#253B7E] font-bold text-base md:text-[25px]" ref={countUpRef} />
                            </div>}
                    </CountUp>
                    <span className="text-xs md:text-base text-center text-[#78716C]">IMPACT OF WATER WELLS 2024</span>
                </div>
            </div>
        </div>;
};
Builder.registerComponent(NewCountUp, {
  name: 'NewCountUp',
  inputs: []
});
export default NewCountUp;