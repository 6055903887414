import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import eventIftarBanner from '../../images/events/iftar-banner.jpg';
import eventAbdulBanner from '../../images/events/event-banner-abdulwahab.jpg';
import eventBelalBanner from '../../images/events/event-banner-belal-assad.jpg';
import eventShaunBanner from '../../images/events/event-banner-shaun.jpg';
import eventYasminBanner from '../../images/events/events-yasmin-malaysia-banner.jpg';
import eventRaniaBanner from '../../images/events/events-rania-malaysia-banner.jpg';
import eventMustafaBanner from '../../images/events/events-mustafa-banner.jpg';
import eventMuslimBanner from '../../images/events/events-muslim-family-banner.jpg';
import eventYoussraBanner from '../../images/events/events-youssra-banner.jpg';
import eventNaumanBanner from '../../images/events/events-nauman-banner.jpg';
import eventMuftiMenekBanner from '../../images/events/event-banner-2024-MuftiMenk.jpg';
import { selectProducts } from '../../store/products';

const EventLandingPage = ({ speaker, type = 'iftar' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const [muftimentProducts, setMuftimentProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  // const [pageType, setPageType] = useState(type)
  // const banner = type === 'iftar' ? eventIftarBanner : type === 'abdulwahab' ? eventAbdulBanner : 'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg'
  const banners = [
    {
      type: 'iftar',
      banner: eventIftarBanner,
      alt: 'Iftar',
    },
    {
      type: 'abdulwahab',
      banner: eventAbdulBanner,
      alt: 'AbdulWahab',
    },
    {
      type: 'belal',
      banner: eventBelalBanner,
      alt: 'Belal Assad',
    },
    {
      type: 'shaun',
      banner: eventShaunBanner,
      alt: 'Shaun King',
    },
    {
      type: 'alihamud',
      banner:
        'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg',
      alt: 'Alihamud',
    },
    {
      type: 'yasmin',
      banner: eventYasminBanner,
      alt: 'Yasmin',
    },
    {
      type: 'rania',
      banner: eventRaniaBanner,
      alt: 'Dr.Rania',
    },
    {
      type: 'mustafa',
      banner: eventMustafaBanner,
      alt: 'Mustafa',
    },
    {
      type: 'muslim',
      banner: eventMuslimBanner,
      alt: 'Muslim Family',
    },
    {
      type: 'youssra',
      banner: eventYoussraBanner,
      alt: 'Youssra',
    },
    {
      type: 'nauman',
      banner: eventNaumanBanner,
      alt: 'Nauman',
    },
    {
      type: 'event',
      banner:
        'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg',
      alt: 'Event',
    },
    {
      type: 'muftimenek',
      banner: eventMuftiMenekBanner,
      alt: 'Mufti Menek',
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    let tempShaunBelalProductsList = [];
    let tempRaniaProductsList = [];
    let tempMustafaProductsList = [];
    let tempMuslimProductsList = [];
    let tempYoussraProductsList = [];
    let tempNaumanProductsList = [];
    let tempMuftiMenekProductsList = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'RM24-ZM-013') {
            if (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim') {
              tempShaunBelalProductsList[8] = inner_item_product;
              tempMuslimProductsList[8] = inner_item_product;
            } else {
              tempProductsList[0] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'RM24-015') {
            tempProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-OP-014') {
            tempProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'TXT-CD-EV-UK-003') {
            tempProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') {
            if (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim') {
              tempShaunBelalProductsList[5] = inner_item_product;
              tempMuslimProductsList[5] = inner_item_product;
            } else {
              tempProductsList[5] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') {
            tempProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {
            tempProductsList[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') {
            tempProductsList[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
            if (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim') {
              tempShaunBelalProductsList[7] = inner_item_product;
              tempMuslimProductsList[7] = inner_item_product;
            } else {
              tempProductsList[9] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempProductsList[10] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-200') {
            tempProductsList[11] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-203') {
            tempProductsList[12] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-206') {
            tempProductsList[13] = inner_item_product;
          }
          if (inner_item_product.creator === 'EVT-EMR-PAL-2024-01' && type === 'abdulwahab') {
            tempProductsList[14] = inner_item_product;
          }
          if (inner_item_product.creator === 'EVT-EMR-PAL-2024-02' && type === 'abdulwahab') {
            tempProductsList[15] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000' && (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')) {
            tempShaunBelalProductsList[0] = inner_item_product;
            tempMuslimProductsList[0] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'EMR-WNT-FND-2023-04' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[1] = inner_item_product;
            tempMuslimProductsList[1] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[2] = inner_item_product;
            tempMuslimProductsList[2] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[3] = inner_item_product;
            tempMuslimProductsList[3] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'EMR-DIS-AA-2024-317' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[4] = inner_item_product;
            tempMuslimProductsList[4] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'WWC-CON-ABW-2023-02-028' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[6] = inner_item_product;
            tempMuslimProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-MN-001' && (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')) {
            tempShaunBelalProductsList[9] = inner_item_product;
            tempMuslimProductsList[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009' && (type === 'belal' || type === 'shaun' || type === 'yasmin')) {
            tempShaunBelalProductsList[10] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'EMR-DIS-BFPL-23-01-204' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[11] = inner_item_product;
            tempMuslimProductsList[11] = inner_item_product;
          }
          if (
            inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049' &&
            (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')
          ) {
            tempShaunBelalProductsList[12] = inner_item_product;
            tempMuslimProductsList[12] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-005' && (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')) {
            tempShaunBelalProductsList[13] = inner_item_product;
            tempMuslimProductsList[13] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-009' && (type === 'belal' || type === 'shaun' || type === 'yasmin' || type === 'muslim')) {
            tempShaunBelalProductsList[14] = inner_item_product;
            tempMuslimProductsList[14] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-MN-001' && type === 'muslim') {
            tempMuslimProductsList[10] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') {
            tempRaniaProductsList[0] = inner_item_product;
            tempMustafaProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-DBD-2023-01-051') {
            tempRaniaProductsList[1] = inner_item_product;
            tempMustafaProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            tempRaniaProductsList[2] = inner_item_product;
            tempMustafaProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
            tempRaniaProductsList[3] = inner_item_product;
            tempMustafaProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') {
            tempRaniaProductsList[4] = inner_item_product;
            tempMustafaProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            tempRaniaProductsList[5] = inner_item_product;
            tempMustafaProductsList[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO-EMR-WNT-GEN-24-06') {
            tempRaniaProductsList[6] = inner_item_product;
            tempMustafaProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-004') {
            tempRaniaProductsList[7] = inner_item_product;
            tempMustafaProductsList[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO-EMR-WNT-GEN-24-03') {
            tempRaniaProductsList[8] = inner_item_product;
            tempMustafaProductsList[8] = inner_item_product;
            tempNaumanProductsList[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO-MA-PAL-OR-1-002') {
            tempRaniaProductsList[9] = inner_item_product;
            tempMustafaProductsList[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-002') {
            tempRaniaProductsList[10] = inner_item_product;
            tempMustafaProductsList[10] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO-EMR-PAL-MASU-001') {
            tempRaniaProductsList[11] = inner_item_product;
            tempMustafaProductsList[11] = inner_item_product;
            tempYoussraProductsList[7] = inner_item_product;
            tempNaumanProductsList[3] = inner_item_product;
            tempMuftiMenekProductsList[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-003') {
            tempRaniaProductsList[12] = inner_item_product;
            tempMustafaProductsList[12] = inner_item_product;
          }
          if (inner_item_product.creator === 'A009-PL-EMR-2023-01-001') {
            tempRaniaProductsList[13] = inner_item_product;
            tempMustafaProductsList[13] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO-EMR-LEB-24-000') {
            tempRaniaProductsList[14] = inner_item_product;
            tempMustafaProductsList[14] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F25-23-01-008') {
            tempYoussraProductsList[0] = inner_item_product;
            tempMuftiMenekProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F150-23-01-010') {
            tempYoussraProductsList[1] = inner_item_product;
            tempMuftiMenekProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            tempYoussraProductsList[2] = inner_item_product;
            tempMuftiMenekProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-1') {
            tempYoussraProductsList[3] = inner_item_product;
            tempMuftiMenekProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-23-01-204') {
            tempYoussraProductsList[4] = inner_item_product;
            tempMuftiMenekProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'ORP-DIS-SOM-2023-01-039') {
            tempYoussraProductsList[5] = inner_item_product;
            tempNaumanProductsList[5] = inner_item_product;
            tempMuftiMenekProductsList[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'ORP-DIS-SOY-2023-01-041') {
            tempYoussraProductsList[6] = inner_item_product;
            tempNaumanProductsList[6] = inner_item_product;
            tempMuftiMenekProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-313') {
            tempYoussraProductsList[8] = inner_item_product;
            tempMuftiMenekProductsList[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'LP-PAL-OR-1-009') {
            tempYoussraProductsList[9] = inner_item_product;
            tempMuftiMenekProductsList[9] = inner_item_product;
          }

          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-2') tempNaumanProductsList[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-4') tempNaumanProductsList[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-1') tempNaumanProductsList[2] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-04') tempNaumanProductsList[4] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') tempNaumanProductsList[7] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-02') tempNaumanProductsList[9] = inner_item_product;

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    // console.log("products=",tempShaunBelalProductsList)
    tempShaunBelalProductsList.length && tempProductsList.unshift(...tempShaunBelalProductsList);
    // console.log("products tempProductsList=",tempProductsList)
    // tempProductsList.forEach(e => console.log(e.creator));
    if (type === 'yasmin') {
      tempProductsList = tempShaunBelalProductsList;
    }
    if (type === 'rania') {
      tempProductsList = tempRaniaProductsList;
    }
    if (type === 'mustafa') {
      tempProductsList = tempMustafaProductsList;
    }
    if (type === 'muslim') {
      tempProductsList = tempMuslimProductsList;
    }
    if (type === 'youssra') {
      tempProductsList = tempYoussraProductsList;
    }
    if (type === 'nauman') {
      tempProductsList = tempNaumanProductsList;
    }
    if (type === 'muftimenek') {
      tempProductsList = tempMuftiMenekProductsList;
    }
    tempProductsList = tempProductsList.filter(n => n);
    // console.log("products=",type,tempProductsList)
    setMuftimentProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // console.log(isIftar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout>
      <div>
        {
          <img
            src={banners.filter(item => item.type === type).map(item => item.banner) || banners[banners.length - 1].banner}
            alt={banners.filter(item => item.type === type).map(item => item.alt) || banners[banners.length - 1].alt}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-5xl md:text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            Plant Your Sadaqah
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {muftimentProducts.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
              <p className="text-[#777] text-xl mb-4">
                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate
                now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventLandingPage;
