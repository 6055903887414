import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import downloadImg from '../../images/others/download-policies.png';
import policiesImg from '../../images/others/policies.jpg';
// import policiesImg from '../images/others/policies.jpg';
// import policiesPdf from '../../public/pdf/MATW-International-Policies-Child-Safegaurding-Policy_Nov2020_Website_version.pdf';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const PoliciesPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['ourPolicies']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['resourcesAndInformation']['ourPolicies']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => {}} />
      <div className={`w-full bg-[url('../src/images/others/policies.jpg')] bg-cover text-white h-auto flex justify-center items-center`}>
        <img src={policiesImg} alt="matw" className="w-full" />
      </div>
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3 md:px-4">
          <div className="col-start-1 col-span-12 rounded-md bg-white p-6 mt-4">
            <div className="grid grid-cols-12">
              <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10">
                <div className="flex flex-row justify-center flex-wrap">
                  <div className="py-2 flex flex-row flex-wrap">
                    <div className="basis-full px-2 text-[#777]">
                      <h2 className="text-[#23408f] text-3xl my-4">Protection & Commitment</h2>
                      <p className=" mb-3">
                        Regular and transparent reporting on our policies, both in Australia and overseas, is an important way we demonstrate our
                        accountability to others. <br />
                        MATW is committed to promoting and protecting all our stakeholders and all individuals and organisations we come in contact
                        with. Our policies ensure responsible conduct as we carry out our charitable work.
                      </p>
                    </div>
                    <div className="basis-full lg:basis-3/4 order-2 lg:order-1 px-2 text-[#777] flex items-center">
                      <Link
                        className="text-[#00a3da] text-xl mt-5"
                        to={'/pdf/MATW-International-Child-Safeguarding-Policy_Website-Version.pdf'}
                        target="_blank"
                      >
                        Download Child Protection Policy
                      </Link>
                    </div>
                    {/* <div className="basis-full lg:basis-1/4 order-1 lg:order-2 px-2">
                      <img src={downloadImg} alt="Ali banat" className="w-full" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default PoliciesPage;
