import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_BASE_URL;
// const Base = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    'X-user-matw': Header,
  },
};

// async function createPaypalOrderPrev(data) {
//   return await fetch(`${Base}/checkout/paypal/create-order`, {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: new Headers({
//         'content-type': 'application/json',
//         'Accept':'application/json'
//     }),
//   })
//     .then(function (res) {
//       return res.json();
//     })
//     .then(function (orderData) {
//       //console.log(orderData);
//       return orderData.id;
//     });
// }
async function capturePaypal(data) {
  // return axios.post(`${Base}/checkout/paypal/capture`, data);

  return await fetch(`${Base}/checkout/paypal/capture-order`, {
    method: 'POST',
    body: JSON.stringify({
      order_id: data.orderID,
    }),
    headers: new Headers({
      'content-type': 'application/json',
      Accept: 'application/json',
      'X-user-matw': Header,
    }),
  })
    .then(function (res) {
      // console.log(res.json());
      return res.json();
    })
    .then(function (orderData) {
      // Successful capture! For demo purposes:
      console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
      //   var transaction = orderData.purchase_units[0].payments.captures[0];
      // iziToast.success({
      //     title: 'Success',
      //     message: 'Payment completed',
      //     position: 'topRight'
      // });
    });
}
function createPaypalOrder(data) {
  return axios.post(`${Base}/checkout/paypal/create-order`, data, config);
}
function createPaypalSubscription(data) {
  return axios.post(`${Base}/checkout/paypal/create-subscription`, data, config);
}
function getPaypalOrderReceipt(id) {
  return axios.get(`${Base}/checkout/paypal/get-order?id=${id}`, config);
}
function getPaypalSubscriptionReceipt(id) {
  return axios.get(`${Base}/checkout/paypal/get-subscription?id=${id}`, config);
}
function getStripeReceipt(id) {
  return axios.get(`${Base}/checkout/stripe/checkout-session?id=${id}`, config);
}
function getAdyenReceipt(request) {
  return axios.get(`${Base}/checkout/stripe/checkout-session?${request}`, config);
}
function sendAdyenResult(checkout_id, search_result) {
  return axios.get(`${Base}/checkout/stripe/checkout-session/success?sessionResult=${search_result}&id=${checkout_id}`, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createPaypalOrder,
  capturePaypal,
  createPaypalSubscription,
  getPaypalOrderReceipt,
  getPaypalSubscriptionReceipt,
  getStripeReceipt,
  getAdyenReceipt,
  sendAdyenResult,
};
