import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import AppealGivingProductBoxUAT from './appealGivingProductBoxUAT';
const AppealLevelGivingUAT = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { givingProducts, lang = 'en', showAllBoxes = false, title,
    // isDetailsPage = false,
    campaign,
    handleSelectedGivingProps } = props;
  let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([0,0,0,0]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const MutationPlugin = (slider) => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        slider.update()
      })
    })
    const config = { childList: true }

    slider.on("created", () => {
      observer.observe(slider.container, config)
    })
    slider.on("destroyed", () => {
      observer.disconnect()
    })
  }
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: 1.3, spacing: 20 },
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 10 },
      },
      '(min-width: 1280px)': {
        slides: { perView: 4, spacing: 10 },
      },
    },
    slides: {
      origin: 'center',
      // perView: 4,
      // spacing: 8,
    },
  // eslint-disable-next-line no-use-before-define
  },[MutationPlugin]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const handleAddQty = (prd, index) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: 1,
    });
    // addProduct(tempPrd, selectedCurrency, 'single', false, true);
    addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
    handleSelectedGiving();
  };
  const handleReduceQty = (prd, index) => {
    if (prd.quantity > 1) {
      let tempPrd = Object.assign({}, prd, {
        quantity: -1,
      });
      editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
      handleSelectedGiving();
    } else {
      handleRemoveProduct(prd)
    }
  };
  const handleAddToGiving = (prd, quantity) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: quantity || 1,
    });
    addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
    handleSelectedGiving();
  };
  const handleRemoveProduct = (prd) => {
    removeProduct(prd)
    handleSelectedGiving();
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];
    // let tempSelectedGiving = [];
    // let tempTotalGiving = 0;
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            // tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
            // tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    // tempSelectedGiving.length === 1 && setIsMinimized(false);
    // setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    console.log("set products=",temp)
    temp = temp.map(item => item).filter(item => item.campaign === campaign)
    setProducts(temp);
    handleSelectedGivingProps();
  }
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    setProducts(givingProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="flex flex-col w-full justify-center mb-0 md:mb-6" ref={contentRef}>
        <h2 className={`text-center text-DarkerBlue text-lg md:text-3xl w-full font-bold mb-2`}>{title}</h2>
      </div>
      <div ref={contentRef} className="md:px-4 overflow-hidden- max-w-full relative">
        <div className="md:w-[95%] mx-auto 2xl:w-full flex-wrap justify-center items-center relative">
          {products && products.length > 1 && (
            <div className="hidden md:flex">
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -left-[18px] md:-left-[36px] z-10 rounded-full ${currentSlide === 0 ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]' : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                    }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <LiaAngleLeftSolid size={25} />
                </div>
              )}
              {instanceRef.current && (
                <div
                  // className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -right-[18px] md:-right-[36px] z-10 rounded-full ${currentSlide === instanceRef.current.track.details.slides.length
                  //   ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]'
                  //   : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                  //   }`}
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -right-[18px] md:-right-[36px] z-10 rounded-full cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <LiaAngleRightSolid size={25} />
                </div>
              )}
            </div>
          )}
           <div className="grid grid-cols-1 ">
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <div className="flex justify-center flex-wrap">
                {products && products.length > 1 && products.map((item,index) => {
                  return (
                    <div className={`basis-1/2 px-1 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 py-1 md:p-2`} key={`top10${index}`}>
                      <AppealGivingProductBoxUAT
                          product={item}
                          lang={lang}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          onAddQty={() => handleAddQty(item, index)}
                          onReduceQty={() => handleReduceQty(item, index)}
                          onAddToGiving={(quantity) => handleAddToGiving(item, quantity)}
                          basket={basketStatesFromLocalStorage}
                        // isAdded={item.isAdded || false}
                        />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
         
        </div>
        
      </div>
    </>
  );
};
export default AppealLevelGivingUAT;
