import { Builder } from '@builder.io/react';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../store/user";
import { selectProducts } from "../../../../../store/products";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { useRef } from 'react';
import SelectCurrency from "../../../../general/selectCurrency";
import ShowCurrencies from "../../../../../utils/showCurrencies";
import CustomButton from "../../../../../components/general/customButton";
const ZakatCalculatorWidget = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const refAssets = useRef(null);
  const refLiabilities = useRef(null);
  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [zakat, setZakat] = useState(0);
  const [selectedNisab, setSelectedNisab] = useState('Silver');
  const [goldNisabAmount, setGoldNisabAmount] = useState(0);
  const [silverNisabAmount, setSilverNisabAmount] = useState(0);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const [zakatAlMalProduct, setZakatAlMalProduct] = useState();
  const goldNisabGrams = 87.48;
  const silverNisabGrams = 595;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleSelectNisab = value => {
    console.log("radio", value);
    setSelectedNisab(value);
    handleCalculateZakat(assets, liabilities, value);
  };
  const handleCurrency = currency => {
    setSelectedCurrency(currency.value);
    handleGoldNisabAmount(currency.value);
    handleSilverNisabAmount(currency.value);
  };
  const handleAssets = value => {
    refAssets.current.value = '';
    if (parseInt(value) >= 0) {
      setAssets(parseInt(value));
      handleCalculateZakat(parseInt(value), liabilities);
    } else {
      setAssets(0);
      setZakat(0);
    }
  };
  const handleLiabilities = value => {
    refLiabilities.current.value = '';
    if (parseInt(value) >= 0) {
      setLiabilities(parseInt(value));
      handleCalculateZakat(assets, parseInt(value));
    } else {
      handleCalculateZakat(assets, 0);
      setLiabilities(0);
    }
  };
  const handleCalculateZakat = (assets, liabilities, nisab = selectedNisab) => {
    let zakatableWealth = parseInt(assets) - parseInt(liabilities);
    console.log("handle-CalculateZakat", parseInt(assets), parseInt(liabilities), silverNisabAmount);
    if (nisab === 'Gold') {
      zakatableWealth > goldNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0);
    } else {
      zakatableWealth > silverNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0);
    }
  };
  const handleKeyDown = value => {
    let checkIfNum;
    if (value.key !== undefined) {
      checkIfNum = value.key === ".";
    }
    return checkIfNum && value.preventDefault();
  };
  const handleGoldNisabAmount = currency => {
    switch (currency) {
      case 'USD':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams));
        break;
      case 'AUD':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AUD) * Number(goldNisabGrams));
        break;
      case 'CAD':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_CAD) * Number(goldNisabGrams));
        break;
      case 'GBP':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_GBP) * Number(goldNisabGrams));
        break;
      case 'EUR':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_EUR) * Number(goldNisabGrams));
        break;
      case 'SGD':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_SGD) * Number(goldNisabGrams));
        break;
      case 'MYR':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_MYR) * Number(goldNisabGrams));
        break;
      case 'AED':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AED) * Number(goldNisabGrams));
        break;
      case 'IDR':
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_IDR) * Number(goldNisabGrams));
        break;
      default:
        setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams));
        break;
    }
  };
  const handleSilverNisabAmount = currency => {
    console.log("handle-SilverNisabAmount=", currency);
    switch (currency) {
      case 'USD':
        console.log("silver nissab usd=", Number(process.env.REACT_APP_SILVER_NISAB_USD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
        handleCalculateZakat(assets, liabilities);
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
        break;
      case 'AUD':
        console.log("silver nissab aud=", Number(process.env.REACT_APP_SILVER_NISAB_AUD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams));
        handleCalculateZakat(assets, liabilities);
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams));
        break;
      case 'CAD':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_CAD) * Number(silverNisabGrams));
        break;
      case 'GBP':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_GBP) * Number(silverNisabGrams));
        break;
      case 'EUR':
        console.log("silver nissab eur=", Number(process.env.REACT_APP_SILVER_NISAB_EUR), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams));
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams));
        break;
      case 'SGD':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_SGD) * Number(silverNisabGrams));
        break;
      case 'MYR':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_MYR) * Number(silverNisabGrams));
        break;
      case 'AED':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AED) * Number(silverNisabGrams));
        break;
      case 'IDR':
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_IDR) * Number(silverNisabGrams));
        break;
      default:
        setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
        break;
    }
  };
  const getAllProducts = () => {
    setIsLoaded(true);
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'RM24-ZM-013') setZakatAlMalProduct(inner_item_product); // Zakat Al Mal
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
  };
  const handleAddNewProduct = (creator, currency, zakat) => {
    let tempProduct = zakatAlMalProduct;
    let customAmount = Number(zakat) ? Number(zakat) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount
    });
    addProduct({
      product: tempProduct,
      currency: currency
    });
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    handleGoldNisabAmount(selectedCurrency);
    handleSilverNisabAmount(selectedCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    handleCalculateZakat(assets, liabilities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goldNisabAmount, silverNisabAmount]);
  useEffect(() => {
    if (selectedCurrencyStore) {
      handleGoldNisabAmount(selectedCurrencyStore);
      handleSilverNisabAmount(selectedCurrencyStore);
      setSelectedCurrency(selectedCurrencyStore);
    } else {
      handleGoldNisabAmount('AUD');
      handleSilverNisabAmount('AUD');
      setSelectedCurrency('AUD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  //init

  return <div className="w-full grid grid-cols-6 gap-4 md:gap-8 justify-center items-center md:container md:mx-auto bg-white rounded-md p-4 md:p-8 lg:p-16 xl:p-24">
            <h1 className="col-span-6 md:col-span-4 md:col-start-2 text-[#253B7E] font-medium text-xl md:text-[25px] text-center mb-4 md:mb-6">
                Zakat calculator 2024: Ensure your contribution matches the nisab with our zakat al mal calculator.
            </h1>
            <div className="col-span-6 flex justify-center mb-4 md:mb-8">
                <div className="w-full md:w-[300px]">
                    <SelectCurrency onChangeCurrency={e => handleCurrency(e)} />
                </div>
            </div>
            <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                <div className={`w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Silver' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`} onClick={() => handleSelectNisab('Silver')}>
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col items-center justify-center">
                            <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Silver' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Silver NISAB</p>
                            <p className={`${selectedNisab === 'Silver' ? 'text-white' : 'text-[#78716C]'} font-light transition-all duration-200 text-sm sm:text-base`}>
                                Silver ({silverNisabGrams} grams) ≅ {ShowCurrencies(selectedCurrency, silverNisabAmount.toFixed(2))}
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Silver' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                            <circle cx="24" cy="24" r="24" />
                            <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                <div className={` w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Gold' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`} onClick={() => handleSelectNisab('Gold')}>
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col items-center justify-center">
                            <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Gold' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Gold NISAB</p>
                            <p className={`${selectedNisab === 'Gold' ? 'text-white' : 'text-[#78716C]'} text-center text-sm sm:text-base font-light transition-all duration-200`}>
                                Gold (87.48 grams) ≅ {ShowCurrencies(selectedCurrency, goldNisabAmount.toFixed(2))}
                                <br />
                                <span className="text-sm">(The Nisaab is applicable according to 21 Karat)</span>
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Gold' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                            <circle cx="24" cy="24" r="24" />
                            <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4">
                <div className="basis-full flex flex-col mb-4 md:mb-6">
                    <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Assets</label>
                    <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Include gold, silver, cash, savings, business assets etc.</small>
                    <input ref={refAssets} type="number" value={assets} className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear' onChange={e => handleAssets(e.target.value)} onKeyDown={e => handleKeyDown(e)} />
                </div>
                <div className="basis-full flex flex-col">
                    <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Liabilities</label>
                    <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Include money you owe, other outgoings due.</small>
                    <div className='min-h-[40px] md:min-h-[48px] w-full rounded border border-stone-300 py-[0.32rem] px-3 flex'>
                        <input ref={refLiabilities} type="number" value={liabilities} className='text-base md:text-md ml-[10px] bg-transparent outline-none transition-all duration-200 ease-linear w-full' onChange={e => handleLiabilities(e.target.value)} onKeyDown={e => handleKeyDown(e)} />
                    </div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4 h-full">
                <div className="basis-full flex flex-col justify-between items-center rounded-md bg-stone-100 p-6">
                    <div className="flex flex-col items-center pt-4">
                        <h2 className="text-[#00a3da] font-medium text-2xl md:text-[30px]">
                            {ShowCurrencies(selectedCurrency, zakat)}
                        </h2>
                        <small className='text-[#78716C] text-lg md:text-[25px]'>Zakat Owed</small>
                    </div>
                    <CustomButton disabled={!(zakat > 0)} title='Pay Zakat Now' onClicked={() => handleAddNewProduct('RM24-ZM-013', selectedCurrency, zakat)} className='mt-4 md:mt-8 uppercase' />
                </div>
            </div>
        </div>;
};
Builder.registerComponent(ZakatCalculatorWidget, {
  name: 'ZakatCalculatorWidget',
  inputs: []
});
export default ZakatCalculatorWidget;
