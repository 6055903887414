import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import services from '../../services';
import { useTranslation } from 'react-i18next';
import { generateLinkWithLang } from '../../utils/linkGenerator';

export default function CarouselSection() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const response = await services.getBanners();
      const sortedBanners = response.data
          .sort((a, b) => a.order - b.order)
          .filter(item => item.status !== 0 && item.section === 'slider');
      setBanners(sortedBanners);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  return (
      <div className="w-full min-h-[200px] md:min-h-[393px] lg:min-h-[550px] xl:min-h-[674px] 2xl:min-h-[800px]">
        {banners.length > 0 && (
            <Carousel
                withArrow={false}
                autoSwitch={true}
                data={banners}
                dotsInside // Custom prop to indicate dots should be inside the image
                renderItem={({ item, index, currentSlide }) => {
                  const isActive = currentSlide === index;
                  return (
                      <div
                          key={`carousel${index}`}
                          className={`relative w-full h-full transition-all transform ${
                              isActive ? 'scale-100' : 'opacity-75 scale-90'
                          }`}
                      >
                        <a href={generateLinkWithLang(item.link, lang)}>
                          <img
                              className="w-full h-full object-cover md:rounded-xl"
                              alt={item.name}
                              src={item.image}
                          />
                        </a>
                      </div>
                  );
                }}
                keyExtractor={item => item.image}
            />
        )}
      </div>
  );
}
