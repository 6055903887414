import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ReactSelect from 'react-select';
import SelectCurrency from '../general/selectCurrency';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import CustomButton from '../general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import staticImages from '../../constants/staticImages';
import PersistentTimerMain from '../general/persistTimerMain';
import { useTranslation } from 'react-i18next';

const styles = {
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const WidgetMain = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    selectedCurrency,
    className,
    defaultValue = 250,
    defaultProduct: { creator = 'DD-EMR-PAL-24-11-01', label = 'Save Lives in Gaza - Where Needed', value = 751 } = {},
    mode = 0, // 0 for 'Single', 1 for 'Monthly' if needed
    suggestedPrices,
  } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  const [selectedPrice, setSelectedPrice] = useState(props.selectedPrice || 250);
  const [productAmount, setProductAmount] = useState(selectedPrice ? null : defaultValue);
  const [currentProduct, setCurrentProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });

  // Added for recurring modes
  const suggestedRecurringModes = [
    { value: 'one-off', label: 'Single' },
    { value: 'month', label: 'Monthly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    const newProducts = [
      'DD-EMR-PAL-24-11-01',
      'DD-EMR-PAL-24-11-02',
      'DD-EMR-PAL-24-11-03',
      'DD-EMR-PAL-24-11-04',
      'DD-EMR-PAL-24-11-05',
      'DD-EMR-PAL-24-11-06',
      'DD-EMR-PAL-24-11-07',
    ];

    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === creator) {
            setSelectedProduct(inner_item_product);
          }

          if (inner_item.name === 'Palestine' && newProducts.includes(inner_item_product.creator)) {
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
          }

          if (inner_item.name === 'Make A Donation') {
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempImportantProducts = tempImportantProducts.concat(tempProductsList);

    // Ensure no empty values
    tempImportantProducts = tempImportantProducts.filter(n => n);

    // Move product with 'MKD-MN-001' creator to 8th position
    const mdkProductIndex = tempImportantProducts.findIndex(product => product.creator === 'MKD-MN-001');

    if (mdkProductIndex !== -1) {
      const [mdkProduct] = tempImportantProducts.splice(mdkProductIndex, 1); // Remove it
      tempImportantProducts.splice(7, 0, mdkProduct); // Insert it at the 8th position (index 7)
    }

    setProductsList(tempImportantProducts);
    setAllProducts(tempMakeDonationProducts);
  };

  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    selectedCurrency(currency);
  };

  const handleProduct = value => {
    allProducts.map(item => item.id === value.value && setSelectedProduct(item));
    setCurrentProduct(value);
  };

  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 250;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    addProduct({ product: tempProduct, currency: currentCurrency });
  };

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // Load products on component mount
  }, []);

  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
  }, [products]);

  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */

  return (
    <div className={`w-full bg-[#eaf7ff] px-3 p-6 md:p-6 sm:px-6 flex flex-col gap-[18px] items-center ${className}`}>
      {/* Donate Now Header */}
      <div className="mb-2 md:mb-5 flex justify-between gap-3 items-center w-full">
        <div className="flex flex-col">
          <h2 className={`text-[#00a3da] text-[32px] sm:text-[38px] font-signika font-bold ${lang === 'fr' ? '!text-[28px] sm:!text-[30px]' : ''}`}>
            Donate Urgently
          </h2>
          <PersistentTimerMain styleMain="!text-[#312e2b] !font-signika" />
        </div>
        <img {...staticImages.policyDonationLogo} className="w-[80px] sm:w-[80px]" alt="palestine" />
      </div>
      {/* Recurring Modes */}
      <div className="w-full flex gap-5 justify-center">
        {suggestedRecurringModes.map((modeOption, index) => (
          <button
            key={index}
            className={`flex-1 text-center h-[60px] font-signika text-xl text-[#b2a79d] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${
              selectedRecurring.value === modeOption.value ? 'bg-[#F60362] !text-white' : 'bg-white text-[#F60362]'
            }`}
            onClick={() => setSelectedRecurring(modeOption)}
          >
            {modeOption.label}
          </button>
        ))}
      </div>

      {/* Product Select */}
      <div className="w-full">
        <ReactSelect
          options={productsList}
          // className="max-h-[50px] [&>div]:!rounded-none"
          classNamePrefix="donate-select donate-select--main"
          value={currentProduct}
          defaultValue={currentProduct}
          isSearchable={false}
          styles={styles}
          onChange={e => handleProduct(e)}
        />
      </div>

      {/* Suggested Prices */}
      {suggestedPrices && (
        <div className="w-full grid grid-cols-3 gap-[18px]">
          {suggestedPrices.map((price, index) => (
            <div key={`prices${index}`} className="col-span-1">
              <div
                className={`font-signika text-xl text-[#b1a79d] transition-all duration-200 cursor-pointer flex justify-center items-center h-[50px] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${
                  selectedPrice === price ? 'bg-[#F60362] !text-white' : 'bg-white text-[#333333]'
                }`}
                onClick={() => {
                  setSelectedPrice(price);
                  setProductAmount('');
                }}
                style={{ width: '100%' }}
              >
                {ShowCurrencies(currentCurrency, price, false)}
                {/* <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span> */}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Currency Select and Amount Input */}
      <div className="w-full items-center rounded-none h-[60px] flex">
        <div className="flex-[0_0_calc(33%-10px)] !h-[60px]">
          <SelectCurrency
            onChangeCurrency={e => handleCurrency(e)}
            classNamePrefix="currency-select-new currency-select-new--main"
            className="[&>div]:!rounded-none !max-h-[60px]"
          />
        </div>
        <input
          value={productAmount}
          name="amount"
          className="h-[60px] text-[#cccac6] w-full truncate p-2 text-xl pl-4 focus:outline-none !rounded-none border border-[#cccac6] border-l-0 !font-signika"
          type="number"
          min={'0'}
          onChange={e => {
            const regex = /^(?:[1-9]\d*)?$/;
            if (regex.test(e.target.value)) {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }
          }}
          placeholder="Other Amount"
        />
      </div>

      {/* Donate Now Button */}
      <div className="w-full">
        <CustomButton
          onClick={() => {
            handleAddNewProduct();
          }}
          icon={<img {...staticImages.donateNowIcon} />}
          title={'Donate Now'}
          disabled={!isLoaded}
          className={`w-full hover:!bg-[#F60362] !h-[60px] !rounded-none !text-[20px] !capitalize !font-signika ${isLoaded ? 'blob red' : ''}`}
        />
      </div>

      {/* Payment Methods Image */}
      <div className="w-full mt-1 bg-[#80CEFF] h-[60px] flex justify-center items-center">
        <img {...staticImages.supportingPaymentBig} className="h-[38px] object-contain" />
      </div>
      {/* <div className="w-full bg-[#80ceff] rounded-md px-3 h-[38px] flex items-center justify-center">
          <img
              src={creditImg}
              alt="Payment Methods"
              className="w-full h-auto"
          />
        </div> */}
    </div>
  );
};

export default WidgetMain;
