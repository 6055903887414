import React, { useEffect, useState } from 'react';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import CarouselSection from '../home/carouselSection';
import impactHeaderImg from '../../images/general/MATW-Home-Banner-TheMATWImpact-Heading.png';
import Edu from '../../images/home/edu.png';
import Country from '../../images/home/country.png';
import Mos from '../../images/home/mos.png';
import Support from '../../images/home/support.png';
import Crisis from '../../images/home/crisis.jpg';
import SadaqahJariyahUk from '../../components/general/sadaqahJariyahUk';
import PolicyBanner from '../../images/banners/usa-banner.jpg';
import launchGood from '../../images/home/launchGood.png';
import ImpactReport from '../../images/home/impactReport.jpg';
import Volunteer from '../../images/home/volunteer.png';
import AllAppeals from '../../images/home/allAppeals.jpg';
import InstaFollow from '../../images/home/instaFollow.jpg';
import Legacy1 from '../../images/home/legacy1.png';
import Legacy2 from '../../images/home/legacy2.png';
import Legacy3 from '../../images/home/legacy3.png';
import Legacy4 from '../../images/home/legacy4.png';
import Legacy11 from '../../images/home/legacy11.png';
import Legacy12 from '../../images/home/legacy12.png';
import Legacy13 from '../../images/home/legacy13.png';
import Legacy14 from '../../images/home/legacy14.png';
import GiveZakat from '../../images/home/give-zakat.jpg';
import ZakatWidget from '../zakat/zakatWidget';
import { Link, useNavigate } from 'react-router-dom';
// import ProductBoxUk from '../../components/general/productBoxUk';
import { useKeenSlider } from 'keen-slider/react';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import GiveWithConfidence from './giveWithConfidence';
import { selectProducts } from '../../store/products';
import ProductBox from '../../components/general/productBox';
import SeoData from '../../utils/seo.json';
import { Helmet } from 'react-helmet-new';
import useHandleAddProduct from '../../utils/handleAddProduct';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const giveConfidenceText = {
  usa: {
    mainPara: `The MATW Project, a well-established charity with seven years of dedication, is expanding to the USA. Your support is invaluable, and every cent goes directly into our impactful projects. Consider starting your own fundraiser with us to witness the life-changing
        results and explore our impact reports to see the difference you can make.`,
    shortPara: `MATW Project, now in the USA! Your support is invaluable. Start your fundraiser, change lives, and explore our impact reports.`,
  },
  singapore: {
    mainPara: `The MATW Project, a well-established charity with seven years of dedication, is expanding to Singapore. Your support is
         invaluable, and every cent goes directly into our impactful projects. Consider starting your own fundraiser with us to witness the life-changing results and explore our impact reports to see the difference you can make.`,
    shortPara: `MATW Project, now in the Singapore! Your support is invaluable. Start your fundraiser, change lives, and explore our impact reports.`,
  },
};

const USAPage = ({ country }) => {
  const navigate = useNavigate();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState('AUD');
  const products = useSelector(state => selectProducts(state));
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0]);
  const [urgentCampaignsProducts, setUrgentCampaignsProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [zakatProduct, setZakatProduct] = useState();
  const [oneBillionProduct, setOneBillionProduct] = useState();
  const [addProduct] = useHandleAddProduct();
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 1,
      spacing: 10,
    },

    spacing: 16,
    loop: false,

    controls: true,
    slideChanged() {
      console.log('slide changed');
    },
  });
  const [scrollPosition, setScrollPosition] = useState('');
  const [active, setActive] = useState(0);
  const legacy = [
    {
      image: Volunteer,
      title: 'Volunteer With Us',
      subTitle: 'Start creating your lasting legacy by volunteering with us, helping those in need, and earning eternal rewards.',
      bottomText: 'Volunteer  with us',
      link: '/Volunteer',
    },
    {
      image: AllAppeals,
      title: 'All Appeals',
      subTitle:
        'Enhance your impact: Join forces with MATW to empower communities. Explore our other urgent appeals that require your vital support.',
      bottomText: 'View All Appeals',
      link: '/all-appeals',
    },
    {
      image: InstaFollow,
      title: 'Follow us on Instagram',
      subTitle: 'Stay connected with MATW on Instagram to keep up with our latest campaigns, news, stories, and more.',
      bottomText: 'Stay updated',
      link: 'https://www.instagram.com/matw_projectusa/',
    },
  ];
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const confidence = {
    usa: [
      {
        image: Crisis,
        title: 'Crisis & Emergency',
        bottomText: 'Donate Now',
        // link: '/checkout?id=eyJwcm9kdWN0IjoiUk0yMy0wMTUiLCJjdXJyZW5jeSI6IkdCUCIsImlkIjo2OSwicXVhbnRpdHkiOjEwMCwiY2F0ZWdvcnlfaWQiOjI1LCJyZWN1cnJpbmciOiJvbmUtb2ZmIn0=',
        link: '/crisis-and-emergencies',
        product: zakatProduct,
      },
      {
        image: launchGood,
        title: 'Crowdfund with us on Launch Good USA',
        bottomText: 'Fundraise with us',
        link: 'https://www.launchgood.com/campaign/countries_in_crisis__emergency_fundraiser_1#!/',
        product: '',
      },
      {
        image: ImpactReport,
        title: 'See our impact reports',
        bottomText: 'Learn more',
        link: '/annual-reports/',
        product: '',
      },
    ],
    singapore: [
      {
        image: Crisis,
        title: 'Crisis & Emergency',
        bottomText: 'Donate Now',
        // link: '/checkout?id=eyJwcm9kdWN0IjoiUk0yMy0wMTUiLCJjdXJyZW5jeSI6IkdCUCIsImlkIjo2OSwicXVhbnRpdHkiOjEwMCwiY2F0ZWdvcnlfaWQiOjI1LCJyZWN1cnJpbmciOiJvbmUtb2ZmIn0=',
        link: '/crisis-and-emergencies',
        product: zakatProduct,
      },
      {
        image: launchGood,
        title: 'Crowdfund with us on Launch Good Singapore',
        bottomText: 'Fundraise with us',
        link: 'https://www.launchgood.com/campaign/countries_in_crisis__emergency_fundraiser_1#!/',
        product: '',
      },
      {
        image: ImpactReport,
        title: 'See our impact reports',
        bottomText: 'Learn more',
        link: '/annual-reports/',
        product: '',
      },
    ],
  };
  /* ------------------------------ Start methods ----------------------------- */
  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 68) {
      setScrollPosition('fixed sm:relative z-10 top-0');
    } else {
      setScrollPosition('');
    }
  };
  const getAllProducts = () => {
    let tempSadaqahProducts = [];
    let tempUrgentCampaignsProducts = [];
    let singaporeProducts = [];
    let tempZakatProduct = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Support An Orphan') {
            if (inner_item_product.creator === 'ORP-DIS-SOM-2023-01-039') {
              // Support An Orphan For A Month
              inner_item_product = Object.assign({}, inner_item_product, {
                name: 'Support an Orphan',
                subTitle:
                  'Almost 5700 children become orphans every day due to war, natural disasters, poverty, disease, stigma, and medical needs. Whatever the cause, many of these children are lost and alone. They need our support now.',
                subTitleCopy:
                  'Almost 5700 children become orphans every day due to war, natural disasters, poverty, disease, stigma, and medical needs.',
                description:
                  'Join us in making a difference by contributing to support orphans with essential provisions such as clothing, food, healthcare, and education',
                // image_link: '/images/landings/uk/supportOrphan1.png',
                navigationLink: '/sadaqah-jariyah/orphans',
              });
              tempSadaqahProducts[2] = inner_item_product;
            }
          }
          if (inner_item.name === 'Build A Masjid') {
            if (inner_item_product.creator === 'GD-MP015') {
              // General Masjid
              inner_item_product = Object.assign({}, inner_item_product, {
                name: 'Build a Mosque',
                subTitle:
                  'Over 400 mosques in Palestine alone have been damaged due to conflict. There are numerous communities in the world that don’t have access to masjids. MATW wishes to change that and fill the lives of these communities with the joy that only a centre for worship can bring.',
                subTitleCopy: 'Join MATW Project to provide places of worship for communites.',
                description: 'Contribute to support MATW in constructing mosques in underserved rural and impoverished communities.',
                // image_link: '/images/landings/uk/buildMasjid1.png',
                navigationLink: '/sadaqah-jariyah/build-a-masjid',
              });
              tempSadaqahProducts[0] = inner_item_product;
            }
          }
          if (inner_item.name === 'Build A Water Well') {
            if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
              // Water Well
              inner_item_product = Object.assign({}, inner_item_product, {
                name: 'Build a Water Well',
                subTitle:
                  'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
                subTitleCopy:
                  'Every year, millions of lives are lost  to water-related diseases.  Join us by providing them with a source of clean water.',
                description: 'Help MATW build vital water wells. Your support funds new constructions and upkeep of existing ones.',
                // image_link: '/images/landings/uk/waterWell1.png',
                navigationLink: '/sadaqah-jariyah/build-a-water-well',
              });
              tempSadaqahProducts[1] = inner_item_product;
            }
          }
          if (inner_item.name === 'Palestine') {
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Palestine Appeal
              inner_item_product = Object.assign({}, inner_item_product, {
                status: '0',
              });
              tempUrgentCampaignsProducts[0] = inner_item_product;
            }
            if (inner_item_product.creator === 'EMR-DIS-MDPL-2023-01-102') {
              // Palestine Emergency Medical Kit

              tempUrgentCampaignsProducts[2] = inner_item_product;
            }
            if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempUrgentCampaignsProducts[3] = inner_item_product; // Palestine Essential Family Food Pack
          }
          if (inner_item.name === 'Earthquake Appeal') {
            if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') {
              // Afghanistan Earthquake Appeal

              tempUrgentCampaignsProducts[1] = inner_item_product;
            }
          }
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') {
              // Sadaqah Jariyah
              inner_item_product = Object.assign({}, inner_item_product, {
                name: 'Sadaqah Jariyah',
                subTitle: `Sadaqah Jariyah: continuous charity while also building your Akhirah. A prime example of that is Ali Banat, who, in his final days, created a legacy of Sadaqah Jariyah, which is now MATW.`,
                subTitleCopy:
                  'Sadaqah Jariyah: continuous charity while also building your Akhirah.A prime example of that is Ali Banat, who, in his final days, created a legacy of Sadaqah Jariyah, which is now MATW.',
                description: 'Contribute to Sadaqah Jariyah and invest in your eternal rewards.',
                status: '1',
                // image_link: '/images/landings/uk/sadqahJariayah1.png',
                navigationLink: '/sadaqah-jariyah',
              });
              tempSadaqahProducts[3] = inner_item_product;
            }
            if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') {
              // Emergency Relief

              tempUrgentCampaignsProducts[4] = inner_item_product;
            }
            if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
              // Food Aid
              inner_item_product = Object.assign({}, inner_item_product, {
                // image_link: '/images/landings/uk/foodAndWaterAid1.png',
                navigationLink: '/food-and-water-aid',
              });
              tempSadaqahProducts[4] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'RM24-ZM-013') tempZakatProduct = inner_item_product; // Zakat Al Mal
          // if (inner_item.name === 'Give Zakat') {
          // };
          if (inner_item.name === 'Food Aid') {
            if (inner_item_product.creator === 'FWD-ODM-001') setOneBillionProduct(inner_item_product); // The one billion legacy
          }
          if (country === 'singapore') {
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Palestine Appeal
              inner_item_product = Object.assign({}, inner_item_product, {
                status: '0',
              });
              singaporeProducts[0] = inner_item_product;
            }
            if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
              //Orphans Around The World
              singaporeProducts[1] = inner_item_product;
            }
            if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
              // Water Well

              singaporeProducts[2] = inner_item_product;
            }
            if (inner_item_product.creator === 'RM24-ZM-013') singaporeProducts[3] = inner_item_product; // Zakat Al Mal
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') singaporeProducts[4] = inner_item_product; // Palestine Medical Relief
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSadaqahProducts = tempSadaqahProducts.filter(n => n);
    // tempZakatProduct = tempZakatProduct.filter(n => n)
    setSadaqahProducts(tempSadaqahProducts);
    setZakatProduct(tempZakatProduct);

    setUrgentCampaignsProducts(country === 'singapore' ? singaporeProducts : tempUrgentCampaignsProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ---------------------------------- Hooks --------------------------------- */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, country]);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className="bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['home']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['home']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} className={scrollPosition} />
      <div className="md:container md:mx-auto mt-0 md:mt-2">
        <CarouselSection />
      </div>
      {/*---------------------------- Most urgent Campagins---------------------------- */}

      <section className="md:container md:mx-auto px:4 sm:px-10 mt-10 ">
        <h1 className="text-center text-[32px] sm:text-[60px]  text-[#253B7E] font-gotcha">MATW's Most Urgent Campaigns</h1>
        <p className="hidden sm:block  leading-7  text-[#78716C] text-center font-brandingMedium text-md lg:text-xl px-4 sm:px-20 md:px-30 lg:px-40 xl:px-8">
          From its inception in 2015 our founder Ali Banat, chose a path of selflessness, leaving a legacy that continues to flourish. Ali Banat's
          true destiny was not in worldly riches, but in uplifting impoverished communities worldwide. He seized this opportunity, and so can you.
          MATW Project are dedicated to making a lasting impact, extending from immediate relief to sustainable development in Palestine, Africa,
          Yemen, Lebanon and beyond. Your support enables us to sustain our crucial initiatives worldwide, start your legacy today.
        </p>
        <p className="sm:hidden leading-7  text-[#78716C] text-center font-brandingMedium  text-md lg:text-xl px-4 sm:px-20 md:px-30 lg:px-40">
          From its inception in 2015 our founder Ali Banat, chose the path of leaving a legacy that continues to flourish. MATW Project are dedicated
          to making a lasting impact in Palestine, Africa, Yemen, Lebanon and beyond. Your support enables to sustain our crucial initiatives
          worldwide, start your legacy today.
        </p>

        <div className="flex flex-wrap  justify-center gap-y-4 sm:gap-8 md:gap-3 xl:gap-x-8 md:gap-y-10 mt-8">
          {urgentCampaignsProducts.map((item, index) => (
            <div key={index} className={`${index === 4 ? 'hidden sm:block' : ''} basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2`}>
              <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
            </div>
            // <div key={index} className={`${index === 4 ? 'hidden sm:block' : ''} max-w-[375px] basis-[46%]  sm:w-[260px] md:basis-1/3`}>
            //     <ProductBoxUk product={item} currency={selectedCurrency} imageClasses={'h-[140px] sm:h-[140px] md:h-[163px] lg:h-[230px] xl:h-[275px]'} />
            // </div>
          ))}
        </div>
      </section>

      {/*---------------------------- impact section ----------------------------*/}

      <section className="md:container md:mx-auto mt-20 px-4 sm:px-20">
        <div className="flex justify-center">
          <img alt="" src={impactHeaderImg} />
        </div>
        <div className="grid mt-10 sm:px-10 gap-y-4 md:px-16 lg:px-3 xl:px-20 grid-cols-4 ">
          <div className="flex flex-col col-span-2 sm:col-span-2 lg:col-span-1 items-center justify-center">
            <div>
              <img alt="" className="w-[90px] h-[90px]" src={Country} />
            </div>
            <div className="text-[#253B7E] text-[20x] md:text-[25px] lg:text-[40px] text-center font-bold">25</div>
            <div className="text-[#78716C] text-[12px] md:text-xl font-brandingMedium">Countries Supported</div>
          </div>
          <div className="flex flex-col col-span-2 sm:col-span-2 lg:col-span-1 items-center justify-center">
            <div>
              <img alt="" className="w-[90px] h-[90px]" src={Mos} />
            </div>
            <div className="text-[#253B7E] text-[20x] md:text-[25px] lg:text-[40px] text-center font-bold">10,111</div>
            <div className="text-[#78716C] text-[12px] md:text-xl font-brandingMedium">Mosques Built</div>
          </div>
          <div className="flex flex-col col-span-2 sm:col-span-2 lg:col-span-1 items-center justify-center">
            <div>
              <img alt="" className="w-[90px] h-[90px]" src={Edu} />
            </div>
            <div className="text-[#253B7E] text-[20x] md:text-[25px] lg:text-[40px] text-center font-bold">61 ,456</div>
            <div className="text-[#78716C] text-[12px] md:text-xl font-brandingMedium">Orphans Educated</div>
          </div>
          <div className="flex flex-col col-span-2 sm:col-span-2 lg:col-span-1 items-center justify-center">
            <div>
              <img alt="" className="w-[90px] h-[90px]" src={Support} />
            </div>
            <div className="text-[#253B7E] text-[20x] md:text-[25px] lg:text-[40px] text-center font-bold">96,625,262</div>
            <div className="text-[#78716C] text-[12px] md:text-xl font-brandingMedium">Beneficiaries Supported</div>
          </div>
        </div>
      </section>

      {/* ----------------------------Sadaqah jariya---------------------------- */}

      <section className="md:container md:mx-auto mt-10 sm:mt-16 px:4 sm:px-10">
        <h1 className="text-center text-[32px] sm:text-[60px]  text-[#253B7E] font-gotcha">Sadaqah Jariyah</h1>
        <p className="hidden sm:block leading-7 text-[#78716C] text-center font-brandingMedium text-md lg:text-xl  px-4 sm:px-20 md:px-30 lg:px-40 xl:px-14">
          Sadaqah Jariyah, the gift that keeps giving, is a charitable practice with everlasting impact. Like Ali Banat, who left a legacy of Sadaqah
          Jariyah through MATW, you can honour a loved one by contributing to a mosque or a water well in their name. This enduring act ensures
          positive change long after they've departed, planting the seeds of everlasting rewards.
        </p>
        <p className="sm:hidden leading-7 text-[#78716C] text-center font-brandingMedium text-md lg:text-xl  px-4 sm:px-20 md:px-30 lg:px-40">
          A gift that keeps giving, like Ali Banat, you can honor a loved one by contributing to a mosque or a water well in their name. Plant the
          seeds of everlasting rewards.
        </p>

        {/* <div className='flex flex-wrap  justify-center  gap-4 sm:gap-8 md:gap-3 md:gap-y-10 mt-8'>
                    {sadaqah.map((item, index) => (
                        <div key={index} className='max-w-[375px]  w-[190px] sm:w-[260px] md:basis-[32%]'>

                {/* <ProductBoxUk product={item} currency={'AUD'} imageClasses={'h-[140px] sm:h-[140px] md:h-[163px] lg:h-[230px] xl:h-[275px]'} /> */}

        {/* </div>
                    ))} */}

        {/* </div>   */}

        <div className="px-4 xl:px-1">
          <div className="bg-[#01A0D8] flex md:grid md:flex-no-wrap flex-wrap mt-8  md:grid-cols-3 lg:grid-cols-5   md:justify-evenly md:items-center ">
            <div
              onClick={() => setActive(0)}
              className={`cursor-pointer border-r text-base sm:text-2xl border-[#00A3DA] ${
                active === 0 ? 'text-white bg-[#00A3DA]' : 'text-[#00A3DA] bg-white'
              }
                    font-bold  flex justify-center items-center h-[50px] lg:h-[100px] basis-1/3 border`}
            >
              Masjids
            </div>
            <div
              onClick={() => setActive(1)}
              className={`cursor-pointer border-r text-base sm:text-2xl border-[#00A3DA] ${
                active === 1 ? 'text-white bg-[#00A3DA]' : 'text-[#00A3DA] bg-white'
              }
                    font-bold  flex justify-center  items-center h-[50px] lg:h-[100px] basis-1/3 border`}
            >
              Water Well
            </div>
            <div
              onClick={() => setActive(2)}
              className={`cursor-pointer border-r text-base sm:text-2xl border-[#00A3DA] ${
                active === 2 ? 'text-white bg-[#00A3DA]' : 'text-[#00A3DA] bg-white'
              }
                    font-bold flex justify-center items-center h-[50px] lg:h-[100px] basis-1/3 border`}
            >
              Orphans
            </div>
            <div
              onClick={() => setActive(3)}
              className={`cursor-pointer border-r text-base sm:text-2xl border-[#00A3DA] ${
                active === 3 ? 'text-white bg-[#00A3DA]' : 'text-[#00A3DA] bg-white'
              }
                    font-bold  flex justify-center items-center border h-[50px] lg:h-[100px] basis-2/5`}
            >
              Sadaqah Jariyah
            </div>
            <div
              onClick={() => setActive(4)}
              className={`cursor-pointer border-r text-base sm:text-2xl border-[#00A3DA] ${
                active === 4 ? 'text-white bg-[#00A3DA]' : 'text-[#00A3DA] bg-white'
              }
                    font-bold flex justify-center items-center border h-[50px] lg:h-[100px] basis-2/5`}
            >
              Food & Water Aid
            </div>
          </div>
          <SadaqahJariyahUk product={sadaqahProducts[active]} currency={selectedCurrency} />
        </div>
      </section>

      {/* ----------------------------Banner -----------------------*/}

      <section className="bg-[#CD3361] my-8 sm:my-14 ">
        <div className="max-w-[1440px] mx-auto flex">
          <div className="basis-[43%] sm:basis-[46%]">
            <img src={GiveZakat} alt="gicve-zakat" />
          </div>
          <div className="basis-[57%] sm:basis-[54%] px-2 sm:px-8 flex flex-col items-center justify-center">
            <div className="text-[10px] lg:text-[32px] lg:leading-10 text-center text-white">
              <span className="bold text-[12px] lg:text-[36px]">Give Zakat:</span> Empowering change through Zakat. Nourish families, sponsor orphans,
              build wells, and more. Your generosity makes a lasting impact, start your legacy today.
            </div>

            <div className="text-center flex justify-center gap-x-2 sm:gap-x-16 mt-1 sm:mt-10">
              <button
                className="text-white text-[10px] lg:text-[32px] rounded-[20px] drop-shadow-lg border-b-4 px-3 sm:px-10 py-1 border-[#a41049] bg-[#F60362] bold"
                // onClick={() => navigate('/checkout?id=eyJwcm9kdWN0IjoiUk0yMy0wMTUiLCJjdXJyZW5jeSI6IlVTRCIsImlkIjo2OSwicXVhbnRpdHkiOjEwMCwiY2F0ZWdvcnlfaWQiOjI1LCJyZWN1cnJpbmciOiJvbmUtb2ZmIn0=')}
                onClick={() => {
                  zakatProduct && addProduct({ product: zakatProduct, currency: selectedCurrency });
                }}
              >
                Give Zakat
              </button>
              <button
                onClick={() => navigate('/zakat-calculator')}
                className="text-white text-[10px] lg:text-[32px] rounded-[20px] drop-shadow-lg border-b-4 px-3 sm:px-10 py-1 border-[#0c6785] bg-[#00A3DA] bold"
              >
                Calculate Zakat
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* ----------------------------Ali banat legacy -----------------------*/}
      <section className="bg-[#093686]  min-h-[700px] ">
        <div className="md:container md:mx-auto  py-5 sm:py-16 md:px-4 lg:px-30 xl:px-3 relative overflow-hidden">
          <h1 className="text-center text-[32px] sm:text-[60px] text-white mb-10 font-gotcha">Ali Banat's Legacy</h1>
          {/* For large devices only */}
          <div className="md:block  hidden px-4 xl:px-8 relative my-6">
            <div ref={sliderRef} className="rounded keen-slider ">
              <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
                <div className="flex-1 pl-3 pr-20 md:pr-3 lg:pr-20">
                  <div className="text-[40px] font-bold leading-9 mb-4 text-[#093484]">Remember our founder, Ali Banat?</div>
                  <div className="text-[20px] xl:text-2xl text-[#78716C] font-brandingMedium">
                    Ali was and still is the perfect example that time is a blessing. At 27 years old Ali was diagnosed with cancer. For some, this
                    diagnosis would be terrifying but for Ali, it was a gift.
                  </div>
                </div>
                <div className=" flex justify-left flex-1 ">
                  {' '}
                  <img alt="" src={Legacy11} />
                </div>
              </div>
              <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
                <div className="flex-1 pl-3 pr-20 md:pr-3 lg:pr-20">
                  <div className="text-[40px] font-bold leading-9 mb-4 text-[#093484]">
                    Journey Towards Akhirah: <div>A Leap of Faith</div>
                  </div>
                  <div className="text-[20px] xl:text-2xl text-[#78716C] font-brandingMedium">
                    He took a long and hard look at his life and decided that everything he had accumulated in his short time on this earth wasn’t
                    anything that he could present to Allah SWT. He sold everything he had from the fast cars to the luxury items, and booked the
                    first flight to Togo in Africa and invested in his Akhirah.
                  </div>
                </div>
                <div className=" flex justify-left flex-1 ">
                  {' '}
                  <img alt="" src={Legacy12} />
                </div>
              </div>
              <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
                <div className="flex-1 pl-3 pr-20 md:pr-3 lg:pr-20">
                  <div className="text-[40px] font-bold leading-9 mb-4 text-[#093484]">MATW: Serving the Ummah Globally</div>
                  <div className="text-[20px] xl:text-2xl text-[#78716C] font-brandingMedium">
                    Fast forward eight years, Muslims Around The World (MATW) now serves the ummah on a global scale.
                  </div>
                </div>
                <div className=" flex justify-left flex-1 ">
                  {' '}
                  <img alt="" src={Legacy13} />
                </div>
              </div>
              <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
                <div className="flex-1 pl-3 pr-20 md:pr-3 lg:pr-20">
                  <div className="text-[40px] font-bold leading-9 mb-4 text-[#093484]">Transformative Journeys: The Power of Your Donation</div>
                  <div className="text-[20px] xl:text-2xl text-[#78716C] font-brandingMedium">
                    At MATW, we hold a firm belief in the strength of collective compassion. Your donation marks the initiation of a transformative
                    journey that will touch lives and leave behind a legacy of positive change.
                  </div>
                </div>
                <div className=" flex justify-left flex-1 ">
                  {' '}
                  <img alt="" src={Legacy14} />
                </div>
              </div>
            </div>
            <BsFillArrowLeftSquareFill
              size={35}
              onClick={() => {
                console.log(instanceRef);
                instanceRef.current.prev();
              }}
              className={'text-[#093484] arrow text-xl absolute left-[35px] top-[240px] hover:cursor-pointer'}
            />
            <BsFillArrowRightSquareFill
              size={35}
              onClick={() => {
                console.log(instanceRef);
                instanceRef.current.next();
              }}
              className={` text-[#093484] arrow text-xl absolute right-[35px] top-[240px] hover:cursor-pointer`}
            />
          </div>
          {/* For small devices */}
          <div className="px-16 md:hidden">
            <div className="  flex border-[10px]  border-t-0 border-r-0 rounded-bl-[20px] min-h-[150px] ">
              <div className="mr-4 -ml-12 -mt-3 max-w-[375px] min-w-[100px]">
                {' '}
                <img alt="" src={Legacy1} className="" />
              </div>
              <div className="mb-6 flex flex-col text-white  ">
                <div className="text-[#F60362]">Oct 2015</div>
                <div className="mt-1 flex items-start justify-start">
                  <span className="text-[64px] mr-4 leading-[48px]">1</span>
                  <div className="text-white text-[14px] leading-4">
                    <div className="text-[16px] mb-2 leading-4">Remember our founder, Ali Banat?</div>
                    At 27 years old Ali was diagnosed with cancer. For some, this diagnosis would be terrifying but for Ali, it was a gift.
                  </div>
                </div>
              </div>
            </div>
            <div className="-mt-[10px] -mr-[11px]  flex border-[10px] border-t-0 border-l-0 rounded-tr-[18px] rounded-br-[20px] min-h-[200px]">
              <div className="flex mt-12 -mr-[44px] justify-between">
                <div className=" sm:mt-1 text-[14px] w-2/3 text-white leading-4 pb-4">
                  He realised that everything he had accumulated in his short time on this earth wasn’t anything that he could present to Allah SWT.
                  He sold everything he had from the fast cars and luxury items, and travelled to Togo in Africa to invest in his Akhirah.
                </div>
                <div className="flex text-white">
                  <span className="text-[64px] mr-2 leading-[48px]">2</span>
                  <div className="-mr-[15px] -mt-3 max-w-[375px] min-w-[100px]">
                    {' '}
                    <img alt="" src={Legacy2} className="h-[85px] w-[85px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="-mt-[10px] -ml-[11px]  flex border-[10px] border-t-0 border-r-0 rounded-tl-[18px] rounded-bl-[20px] min-h-[200px]">
              <div className="mr-4 sm:mr-10 -ml-12 mt-12 max-w-[375px] min-w-[100px] ">
                {' '}
                <img alt="" src={Legacy3} className="h-[100px] w-[100px]" />
              </div>
              <div className="flex mt-12 flex-col text-white  pb-4">
                <div className="text-[#F60362]">2023</div>
                <div className="mt-1 flex items-start justify-start">
                  <span className="text-[64px] mr-4 leading-[48px]">3</span>
                  <div className="text-white text-[14px] leading-4 w-2/3">
                    Fast forward eight years, Muslims Around The World (MATW) now serves the ummah on a global scale.
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`relative -mt-[10px] -mr-[11px]  flex   rounded-tr-[18px] rounded-br-[20px] min-h-[150px]
                        before:content-['']  before:w-[10px]  before:h-[40px] before:bg-[#e5e7eb] before:absolute
                        before:-z-[1px] before:right-1 before:border before:rounded-tr-[25px]
                        `}
            >
              <div className="flex mt-12 -mr-[30px] justify-between">
                <div className="mt-2 text-[14px] w-2/3 text-white leading-3">
                  At MATW, we hold a firm belief in the strength of collective compassion. Your donation marks the initiation of a transformative
                  journey that will touch lives and leave behind a legacy of positive change.
                </div>
                <div className="flex text-white">
                  <span className="text-[64px] mr-4 leading-[48px]">4</span>
                  <div className="max-w-[375px] min-w-[100px] -mr-[15px] -mt-3">
                    {' '}
                    <img alt="" src={Legacy4} className="h-[85px] w-[85px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------------------Ali Banat------------------------------  */}

      <section className="md:container md:mx-auto  px-4 sm:px-20 xl:px-10">
        <div className="md:container md:mx-auto  flex flex-col flex-wrap">
          <div className="basis-full rounded-xl mt-6 bg-white p-2 lg:p-10 text-center shadow-lg">
            <h2 className="px-10 text-center font-gotcha mb-4 sm:mb-10 text-[32px] sm:text-[60px] text-[#f60362] ">
              Ali Banat <span className="text-[#23408f] ">, his Life, his Legacy, his Impact.</span>
            </h2>
            <div className="col-span-12 h-[200px]  border-4 border-[#F60362] sm:h-[300px] md:h-[400px] lg:h-[600px]">
              <iframe
                className="video"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}
              />
            </div>
          </div>

          <div className="flex items-center justify-center my-5 sm:my-10">
            <Link
              to="/ali-banat"
              className="flex rounded-md  items-center justify-center h-[35px] sm:h-[58px]  w-[197px] sm:w-[410px] bg-[#F60362] text-sm sm:text-2xl text-white font-light"
            >
              Learn More About Ali Banat
            </Link>
          </div>
        </div>
      </section>

      {/* ----------------------------Give with confidence ----------------------------*/}

      <section className="md:container md:mx-auto sm:mt-10 px-3 sm:px-10 md:px-20 xl:px-5">
        <h1 className="text-center mb-3 text-[32px] sm:text-[60px]  text-[#253B7E] font-gotcha">Give With Confidence</h1>
        <p className="hidden sm:block mb-16 leading-7 text-[#78716C] text-center font-brandingMedium text-md lg:text-xl px-4 sm:px-20 sm:px-30 lg:px-40 xl:px-20">
          {giveConfidenceText[country].mainPara}
        </p>
        <p className="sm:hidden leading-7 text-[#78716C] text-center font-brandingMedium  text-[14px] lg:text-xl px-4 sm:px-20 sm:px-30 lg:px-40">
          {giveConfidenceText[country].shortPara}
        </p>

        <div className="flex flex-wrap  justify-center  gap-2 gap-y-3 sm:gap-4 md:gap-3 lg:gap-6 xl:gap-8 md:gap-y-10 mt-4 sm:mt-8">
          {confidence[country].map((item, index) => (
            <div key={index} className="basis-[48%] sm:basis-[31%] md:basis-[32%] lg:basis-[31%]">
              <GiveWithConfidence
                minHeight={`min-h-[240px]`}
                image={item.image}
                title={item.title}
                des={item.bottomText}
                link={item.link}
                product={item.product}
                currency={selectedCurrency}
              />
            </div>
          ))}
        </div>
      </section>
      {/* 100 % Policy banner */}
      <section className="bg-[#00ADEF]  my-6 sm:my-8 flex justify-center">
        <img alt="banner" src={PolicyBanner} />
      </section>

      {/*-----------------------------1 billion legacy ----------------------------*/}

      <section className="md:container md:mx-auto px-4 sm:px-10 xl:px-0  ">
        <div className="md:container md:mx-auto px-4 xl:px-10 sm:px-10  relative">
          <h1 className="text-center mb-3 text-[32px] sm:text-[60px]  text-[#253B7E] font-gotcha">1 Billion Legacy</h1>
          <p className="hidden sm:block leading-7 text-[#78716C] text-center font-brandingMedium text-md lg:text-xl px-4 sm:px-20 md:px-30 lg:px-40 xl:px-28 mb-10">
            Join MATW's mission: We are committed to nourishing one billion people and combatting hunger. While we have already provided meals to 9
            million individuals, it's just 9% of our journey. Your support is pivotal to our success. We oversee the entire process, from the kitchen
            to delivery, not only feeding a billion but also generating employment opportunities. With a monthly donation of just $30, equivalent to
            $1 a day, you can make a life-changing impact, as this $1 can provide 4 meals. Your contribution wields immense power in the
            transformation of lives.
          </p>
          <p className="sm:hidden  text-[#78716C] text-center font-brandingMedium text-[14px] leading-5 lg:text-xl px-2 sm:px-20 md:px-30 lg:px-40 mb-5">
            Join MATW's mission to nourish one billion and combat hunger. With a monthly donation of $30 (just $1 a day), you can make a life-changing
            impact - $1 provides 4 meals and creates job opportunities. Help transform lives today.
          </p>
          <div
            className="relative w-full cursor-pointer flex flex-wrap justify-center  md:bg-[url('../public/images/landings/one-billion/one-billion-new.png')] bg-center sm:bg-center bg-cover md:py-8 max-[500px]:min-h-[100px] min-[501px]:min-h-[120px] sm:min-h-[190px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] sm:px-4 md:px-0"
            // onClick={() => navigate('/checkout?id=eyJwcm9kdWN0IjoiRldELU9ETS0wMDEiLCJjdXJyZW5jeSI6IkdCUCIsImlkIjoyOTIsInF1YW50aXR5IjoxMDAsImNhdGVnb3J5X2lkIjoyMywicmVjdXJyaW5nIjoib25lLW9mZiJ9')}
            onClick={() => {
              addProduct({ product: oneBillionProduct, currency: selectedCurrency });
            }}
          >
            <div className="flex justify-between items-center  md:container md:mx-auto md:px-16">
              <div className="cursor-default" onClick={e => e.stopPropagation()}>
                <ZakatWidget
                  hasSuggestPrice
                  className={'rounded-[0px] max-w-[100%] md:max-w-[380px] w-full'}
                  defaultProduct={{
                    creator: 'FWD-ODM-001',
                    label: 'Food Aid',
                    value: 103,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            // onClick={() => navigate('/checkout?id=eyJwcm9kdWN0IjoiRldELU9ETS0wMDEiLCJjdXJyZW5jeSI6IkdCUCIsImlkIjoyOTIsInF1YW50aXR5IjoxMDAsImNhdGVnb3J5X2lkIjoyMywicmVjdXJyaW5nIjoib25lLW9mZiJ9')}
            onClick={() => {
              addProduct({ product: oneBillionProduct, currency: selectedCurrency });
            }}
            className="hidden lg:flex justify-end flex-col items-center absolute lg:right-28 bottom-12 xl:right-[8rem] xl:bottom-[7rem] font-bold"
          >
            <div className="text-white">
              <span className="text-[30px] xl:text-[44px]">- THE -</span> <span className="text-[40px] xl:text-[60px]">1 BILLION</span>{' '}
              <span className="font-gotcha text-[30px] xl:text-[44px]"> Legacy</span>
            </div>
            <button className="bg-[#ED0B6D] text-white px-4 py-2 rounded-2xl xl:w-[250px] xl:text-[30px]">DONATE NOW</button>
          </div>
        </div>
        <div
          className="px-4 sm:hidden mt-3"
          // onClick={() => navigate('/checkout?id=eyJwcm9kdWN0IjoiRldELU9ETS0wMDEiLCJjdXJyZW5jeSI6IkdCUCIsImlkIjoyOTIsInF1YW50aXR5IjoxMDAsImNhdGVnb3J5X2lkIjoyMywicmVjdXJyaW5nIjoib25lLW9mZiJ9')}
          onClick={() => {
            addProduct({ product: oneBillionProduct, currency: selectedCurrency });
          }}
        >
          <div className="flex pb-9 justify-end flex-col items-center bg-cover bg-no-repeat sm:hidden bg-[url('../public/images/landings/one-billion/one-billion-mobile-new.png')] h-[390px]">
            <div className="text-white">
              <span className="text-[28px]">- THE -</span> <span className="text-[36px] ">1 BILLION</span>{' '}
              <span className="font-gotcha text-[28px]"> Legacy</span>
            </div>
            <button className="bg-[#ED0B6D] text-white px-4 py-2 rounded-lg">DONATE NOW</button>
          </div>
        </div>
      </section>

      {/* build Your Own Legacy Today */}

      <section className="md:container md:mx-auto mt-10 sm:mt-20 px-4 sm:px-10 md:px-20 xl:px-0">
        <h1 className="text-center mb-3 text-[32px] sm:text-[60px]  text-[#253B7E] font-gotcha">Build Your Own Legacy Today</h1>
        <p className="sm:leading-7 text-[#78716C] text-center font-brandingMedium text-[14px] leading-5 lg:text-xl px-5 sm:px-20 md:px-30 lg:px-32 xl:px-36">
          Empower your legacy with MATW: Crafting a meaningful impact for this world and the hereafter through voluntary work, compassionate
          donations, and staying connected on Instagram.
        </p>
        <div className="flex flex-wrap  justify-center  gap-3 sm:gap-8 md:gap-3 xl:gap-8 md:gap-y-10 mt-8">
          {legacy.map((item, index) => (
            <div key={index} className="max-w-[375px]  basis-[47%] sm:w-[260px] md:basis-[32%] lg:basis-[31%]">
              <GiveWithConfidence image={item.image} subTitle={item.subTitle} title={item.title} des={item.bottomText} link={item.link} />
            </div>
          ))}
        </div>
      </section>
    </NewHelmetLayout>
  );
};

export default USAPage;
