import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';
import { useRef } from 'react';
// import MultiSelectProductsBox from '../../../components/general/multiSelectProductsBox';
// import { useKeenSlider } from 'keen-slider/react';
// import Arrow from '../../home/components/tab-arrow';

const PalestineTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    palestineHungerProducts,
    palestineEmergencyProducts,
    palestineRefugeeSettlementProducts,
    saveOrphansProducts,
    arabic = false,
    // sheepGoatProducts,
    // cowShareProducts,
    // cowProducts,
    lang = 'en',
    bgColor,
  } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [loadedQurban, setLoadedQurban] = useState(false);
  // const [currentSlideQurban, setCurrentSlideQurban] = useState(0);
  const tabButtonsProducts = [
    {
      // title: arabic ? 'فلسطين الطوارئ' : 'PALESTINE EMERGENCY',
      title: arabic ? 'فلسطين الطوارئ' : lang === 'fr' ? 'Urgence Palestinienne' : lang === 'ar' ? 'فلسطين الطوارئ' : 'PALESTINE EMERGENCY',
      description: '',
      products: palestineEmergencyProducts,
    },
    {
      // title: arabic ? 'إنقاذ الأيتام' : 'save orphans',
      title: arabic ? 'إنقاذ الأيتام' : lang === 'fr' ? 'sauver les orphelins' : lang === 'ar' ? 'إنقاذ الأيتام' : 'save orphans',
      description: '',
      products: saveOrphansProducts,
    },
    {
      // title: arabic ? 'مستوطنة اللاجئين الفلسطينيين' : 'Palestine Refugee Settlement',
      title: arabic
        ? 'مستوطنة اللاجئين الفلسطينيين'
        : lang === 'fr'
        ? 'Installation des réfugiés palestiniens'
        : lang === 'ar'
        ? 'مستوطنة اللاجئين الفلسطينيين'
        : 'Palestine Refugee Settlement',
      description: '',
      products: palestineRefugeeSettlementProducts,
    },
    {
      // title: arabic ? 'فلسطين جوعى' : 'Palestine Hunger',
      title: arabic ? 'فلسطين جوعى' : lang === 'fr' ? 'La faim en Palestine' : lang === 'ar' ? 'فلسطين جوعى' : 'Palestine Hunger',
      description: '',
      products: palestineHungerProducts,
    },
  ];
  // const [sliderRefQurban, instanceRefQurban] = useKeenSlider({
  //   mode: "snap",
  //   slideChanged(slider) {
  //     setCurrentSlideQurban(slider.track.details.rel)
  //   },
  //   created() {
  //     setLoadedQurban(true)
  //   },
  //   slides: {
  //     origin: "auto",
  //     perView: 1,
  //     spacing: 8,
  //   },
  // })
  // const hasQurbanTab = sheepGoatProducts && cowShareProducts && cowProducts;
  // if (hasQurbanTab) {
  //   tabButtonsProducts.unshift({
  //     title: 'Qurban',
  //     isMultiSelectProduct: true,
  //     boxTitle: [sheepGoatProducts.title, cowShareProducts.title, cowProducts.title],
  //     products: [sheepGoatProducts.products, cowShareProducts.products, cowProducts.products],
  //     checkboxProducts: sheepGoatProducts.checkboxProducts,
  //     bundleProduct: [sheepGoatProducts.bundleProduct, cowShareProducts.bundleProduct, null],
  //     description: 'Embrace the opportunity to increase your rewards during these beloved ten days of Dhul Hijjah.',
  //   });
  // }
  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className={`${bgColor ? bgColor : 'bg-[#253B7E]'}`} dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={handleTabChange.bind(this, index)}
              className={`${
                index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${
                arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
              }`}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {/* {hasQurbanTab && activeTabProducts === 0 && (
              <>
                <h1 className="text-white text-lg md:text-3xl leading-7 text-center font-bold mb-2 md:mb-4 w-full md:w-2/3 mx-auto">
                  {tabButtonsProducts[0].description}
                </h1>
                <div ref={contentRef} className="hidden md:flex flex-wrap justify-center">
                  {tabButtonsProducts[0].products.map((item, index) => {
                    return (
                      <div className="basis-1/2 lg:basis-1/3 p-2" key={`multiproducts${index}`}>
                        <MultiSelectProductsBox
                          hideOuterBundleImage
                          className={`!w-full min-h-[600px]`}
                          products={item}
                          checkboxProducts={tabButtonsProducts[0].checkboxProducts || null}
                          bundleProduct={tabButtonsProducts[0].bundleProduct[index] || null}
                          title={tabButtonsProducts[0].boxTitle[index]}
                          currency={selectedCurrency || 'AUD'}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="w-full md:hidden flex-wrap justify-center items-center relative">
                  <div ref={sliderRefQurban} className="keen-slider">
                    {tabButtonsProducts[0].products.map((item, index) => {
                      return (
                        <div className="keen-slider__slide w-full max-w-[300px] flex justify-center" key={`multiproductsMobile${index}`}>
                          <div className="max-w-[450px] w-full">
                            <MultiSelectProductsBox
                              hideOuterBundleImage
                              className={`!w-full ${isMenuOpen ? 'min-h-[800px]' : 'min-h-[575px]'}`}
                              products={item}
                              checkboxProducts={tabButtonsProducts[0].checkboxProducts || null}
                              bundleProduct={tabButtonsProducts[0].bundleProduct[index] || null}
                              title={tabButtonsProducts[0].boxTitle[index]}
                              currency={selectedCurrency}
                              onSelectCurrencyOpen={() => {
                                console.log('menu open');
                                setIsMenuOpen(true);
                              }}
                              onSelectCurrencyClose={() => {
                                console.log('menu close');
                                setIsMenuOpen(false);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[450px] w-full">
                    {loadedQurban  && (
                      <div className="flex justify-center items-center relative w-full">
                        <Arrow left onClick={e => e.stopPropagation() || instanceRefQurban.current?.prev()} disabled={currentSlideQurban === 0} />
                        {[...Array(instanceRefQurban.current?.track.details.slides.length).keys()].map(idx => {
                          return (
                            <button
                              key={`q${idx}`}
                              onClick={() => {
                                instanceRefQurban.current?.moveToIdx(idx);
                              }}
                              className={
                                'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                                (currentSlideQurban === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                              }
                            ></button>
                          );
                        })}
                        <Arrow
                          onClick={e => e.stopPropagation() || instanceRefQurban.current?.next()}
                          disabled={currentSlideQurban === instanceRefQurban.current?.track.details.slides.length - 1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )} */}
            {tabButtonsProducts.map(
              (item, index) =>
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    <div className="flex w-full justify-center mb-6">
                      <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3">{item.description}</p>
                    </div>
                    <div ref={contentRef}>
                      <TabContent
                        arabic={arabic}
                        lang={lang}
                        products={item.products}
                        currency={selectedCurrency}
                        showDots={true}
                        showArrows={false}
                        showMultiProductsOnRow={false}
                        viewAppealsLink={item.viewAppealsLink || null}
                      />
                    </div>
                  </React.Fragment>
                ),
              // !(hasQurbanTab && index === 0) && (
              // ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PalestineTabs;
