import { Builder } from '@builder.io/react';

import { Helmet } from 'react-helmet-new';
const MetaTags = ({
  title = '',
  description = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={description} />
        </Helmet>
        <div></div>
        </>;
};
Builder.registerComponent(MetaTags, {
  name: 'MetaTags',
  inputs: [{
    "name": "title",
    "type": "string"
  }, {
    "name": "description",
    "type": "string"
  }]
});
export default MetaTags;