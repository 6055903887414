import React from 'react';

import ImpactReportRiceImg from '../../../images/landings/palestine/impact-report-rice.png';
import ImpactReportFlourImg from '../../../images/landings/palestine/impact-report-flour.png';
import ImpactReportMilkImg from '../../../images/landings/palestine/impact-report-milk.png';
import ImpactReportCannedFoodImg from '../../../images/landings/palestine/impact-report-canned-food.png';
import ImpactReportHotMealImg from '../../../images/landings/palestine/impact-report-hot-meal.png';
import ImpactReportOilImg from '../../../images/landings/palestine/impact-report-oil.png';
import ImpactReportDatesImg from '../../../images/landings/palestine/impact-report-dates.png';
import ImpactReportLentilImg from '../../../images/landings/palestine/impact-report-lentil.png';
import ImpactReportSummacImg from '../../../images/landings/palestine/impact-report-summac.png';
import ImpactReportMacaroniImg from '../../../images/landings/palestine/impact-report-macaroni.png';
import ImpactReportSugarImg from '../../../images/landings/palestine/impact-report-sugar.png';
import ImpactReportWaterImg from '../../../images/landings/palestine/impact-report-water.png';
import ImpactReportVegetableImg from '../../../images/landings/palestine/impact-report-vegetable.png';
import ImpactReportFoodPackImg from '../../../images/landings/palestine/impact-report-food-pack.png';

import ImpactReportBreadImg from '../../../images/landings/palestine/impact-report-bread.png';
import ImpactReportChickenImg from '../../../images/landings/palestine/impact-report-chicken.png';
import ImpactReportSweetBoxesImg from '../../../images/landings/palestine/impact-report-sweet-boxes.png';
import ImpactReportEggTrayImg from '../../../images/landings/palestine/impact-report-egg-tray.png';
import ImpactReportFreshFishImg from '../../../images/landings/palestine/impact-report-fresh-fish.png';
import ImpactReportAdultDiapersImg from '../../../images/landings/palestine/impact-report-adult-diapers.png';
import ImpactReportSurgicalSuppliesImg from '../../../images/landings/palestine/impact-report-surgical-supplies.png';
import ImpactReportBabyDiapersImg from '../../../images/landings/palestine/impact-report-baby-diapers.png';
import ImpactReportHygieneKitsImg from '../../../images/landings/palestine/impact-report-hygiene-kits.png';
import ImpactReportMedicalBedsImg from '../../../images/landings/palestine/impact-report-medical-beds.png';
import ImpactReportPatientMinorImg from '../../../images/landings/palestine/impact-report-patient-minor.png';
import ImpactReportPatientMajorImg from '../../../images/landings/palestine/impact-report-patient-major.png';
import ImpactReportBlanketsImg from '../../../images/landings/palestine/impact-report-blankets.png';
import ImpactReportMattresesImg from '../../../images/landings/palestine/impact-report-mattreses.png';
import ImpactReportPillowsImg from '../../../images/landings/palestine/impact-report-pillows.png';
import ImpactReportTentsImg from '../../../images/landings/palestine/impact-report-tents.png';
import ImpactReportTowelsImg from '../../../images/landings/palestine/impact-report-towels.png';
import ImpactReportClothingImg from '../../../images/landings/palestine/impact-report-clothing.png';
import ImpactReportWinterJacketImg from '../../../images/landings/palestine/impact-report-winter-jacket.png';
import ImpactReportPrayerAbayaImg from '../../../images/landings/palestine/impact-report-prayer-abaya.png';
import ImpactReportGiftsImg from '../../../images/landings/palestine/impact-report-gifts.png';
import ImpactReportFuelImg from '../../../images/landings/palestine/impact-report-fuel.png';
import ImpactReportGasTankImg from '../../../images/landings/palestine/impact-report-gas-tank.png';

import { useKeenSlider } from 'keen-slider/react';

const reportItems = [
  {
    title: 'Flour Bag',
    value: '25,000',
    img: ImpactReportFlourImg,
  },
  {
    title: 'Liters of Oil',
    value: '8,500 L',
    img: ImpactReportOilImg,
  },
  {
    title: 'Dates',
    value: '155 Tons',
    img: ImpactReportDatesImg,
  },

  {
    title: 'Lentil',
    value: '10 Tons',
    img: ImpactReportLentilImg,
  },
  {
    title: 'Summac',
    value: '66 Tons',
    img: ImpactReportSummacImg,
  },
  {
    title: 'Rice',
    value: '69 Tons',
    img: ImpactReportRiceImg,
  },

  {
    title: 'Baby Milk',
    value: '28700',
    img: ImpactReportMilkImg,
  },
  {
    title: 'Bread Packs',
    value: '14,000',
    img: ImpactReportBreadImg,
  },
  {
    title: 'Canned Food',
    value: '25,500',
    img: ImpactReportCannedFoodImg,
  },
  {
    title: 'Hot Meal',
    value: '822,000',
    img: ImpactReportHotMealImg,
  },
  {
    title: 'Macaroni',
    value: '7 Tons',
    img: ImpactReportMacaroniImg,
  },
  {
    title: 'Sugar',
    value: '55 Tons',
    img: ImpactReportSugarImg,
  },
  {
    title: 'Water',
    value: '20 Million L',
    img: ImpactReportWaterImg,
  },
  {
    title: 'Vegetable Box',
    value: '45,480',
    img: ImpactReportVegetableImg,
  },

  {
    title: 'Food Packs',
    value: '208,077',
    img: ImpactReportFoodPackImg,
  },

  {
    title: 'Chicken',
    value: '5,100',
    img: ImpactReportChickenImg,
  },
  {
    title: 'Sweet Boxes',
    value: '500',
    img: ImpactReportSweetBoxesImg,
  },
  {
    title: 'Egg Tray',
    value: '5,700',
    img: ImpactReportEggTrayImg,
  },
  {
    title: 'Fresh Fish',
    value: '1000',
    img: ImpactReportFreshFishImg,
  },
  {
    title: 'Adult Diapers',
    value: '1,965',
    img: ImpactReportAdultDiapersImg,
  },

  {
    title: 'Surgical Supplies',
    value: '19,840',
    img: ImpactReportSurgicalSuppliesImg,
  },
  {
    title: 'Baby Diapers',
    value: '8,340',
    img: ImpactReportBabyDiapersImg,
  },
  {
    title: 'Hygiene Kits',
    value: '25,100',
    img: ImpactReportHygieneKitsImg,
  },
  {
    title: 'Medical Beds',
    value: '65',
    img: ImpactReportMedicalBedsImg,
  },
  {
    title: 'Patient Minor Treatment',
    value: '4,340',
    img: ImpactReportPatientMinorImg,
  },
  {
    title: 'Patient Major Treatment',
    value: '25',
    img: ImpactReportPatientMajorImg,
  },
  {
    title: 'Blankets',
    value: '11,350',
    img: ImpactReportBlanketsImg,
  },
  {
    title: 'Mattresses',
    value: '5,520',
    img: ImpactReportMattresesImg,
  },
  {
    title: 'Pillows',
    value: '1,000',
    img: ImpactReportPillowsImg,
  },
  {
    title: 'Tents',
    value: '851',
    img: ImpactReportTentsImg,
  },
  {
    title: 'Towels',
    value: '540',
    img: ImpactReportTowelsImg,
  },
  {
    title: 'Clothing',
    value: '17,375',
    img: ImpactReportClothingImg,
  },
  {
    title: 'Winter Jacket',
    value: '17,000',
    img: ImpactReportWinterJacketImg,
  },
  {
    title: 'Prayer Abaya',
    value: '750',
    img: ImpactReportPrayerAbayaImg,
  },
  {
    title: 'Gifts for Children',
    value: '1,410',
    img: ImpactReportGiftsImg,
  },
  {
    title: 'Fuel',
    value: '30,000',
    img: ImpactReportFuelImg,
  },
  {
    title: 'Gas Tank',
    value: '1,000',
    img: ImpactReportGasTankImg,
  },
];
const ImpactReport = () => {
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 3,
      spacing: 10,
    },
    loop: true,
    controls: true,
    slideChanged() {},
  });
  return (
    <section className="bg-LighterBlue">
      <div className="mx-auto max-w-[1120px] px-5 py-[30px] md:py-[50px]">
        <img src="/images/landings/crisis/palestine/impact.png" alt="" className="w-[200px] md:w-[300px] mx-auto" />
        <div className="mt-2.5 text-center font-brandingBold font-bold text-DarkerBlue text-[18px] md:text-[25px] flex flex-col md:block">
          <span>Total Palestine Impact since Oct 7th 2023</span>
        </div>
        <div className="mt-[30px] gap-y-6 hidden md:grid grid-cols-4 lg:grid-cols-5 ">
          {reportItems.map((item, index) => (
            <ReportItem item={item} key={index} />
          ))}
        </div>
        <div className="block md:hidden px-4 relative ">
          <div ref={sliderRef} className="keen-slider px-1 py-2">
            {reportItems.map((item, index) => (
              <ReportItem item={item} />
            ))}
          </div>
          <svg
            onClick={() => {
              instanceRef.current.prev();
            }}
            className={`arrow text-xl absolute left-0 top-12`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path d="M15.6 19.7126L8.59998 12.7126L15.6 5.71256" stroke="#14A2DC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <svg
            onClick={() => {
              instanceRef.current.next();
            }}
            className={`arrow text-xl absolute right-0 top-12`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.40002 5.71335L15.4 12.7133L8.40002 19.7133"
              stroke="#14A2DC"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

function ReportItem({ item }) {
  return (
    <div className="keen-slider__slide min-w-[200px]">
      <div className="h-[65px] w-[65px] mx-auto flex items-center justify-center bg-white rounded-full">
        <img src={item.img} alt="" className="w-[35px] h-[35px] object-contain" />
      </div>
      <p className="font-brandingBold text-[18px] md:text-[26px] md:leading-[34px] text-center text-DarkerBlue">{item.value}</p>
      <p className="text-[14px] md:text-[16px] font-brandingMedium text-center uppercase text-[#797979]">{item.title}</p>
    </div>
  );
}

export default ImpactReport;
