import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import Skeleton from 'react-loading-skeleton';
import SeoData from '../../utils/seo.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const HealthLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [products, setProducts] = useState({
    name: '',
    products: [0, 0, 0, 0],
  });
  const [isLoading, setIsLoading] = useState(true);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [meta, setMeta] = useState({
    parent: '',
    child: '',
  });
  const projectNoorCategor = {
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-health-eye-restoration-for-1-person.jpg',
    name: 'Project Noor',
    page_title: 'Project Noor',
    description: 'Project Noor aims to improve the quality of life for visually impaired individuals.',
    id: 1,
  };
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProductsByName = async () => {
    setIsLoading(true);
    try {
      const response = await services.categoryProductsByName('health');
      if (Object.keys(response.data).length === 0) {
        navigate(generateLinkWithLang('/', lang));
      } else {
        response.data.tile_image = '/images/landings/sadaqah-jariyah/health-banner.webp';
        let temp = response.data;
        temp.products.map(item => {
          if (item.name === 'Treatment For CP Child') {
            // item.description = 'Donate to help provide prosthetics to disabled Bangladeshis.'
          }
          if (item.name === 'Disability Support') {
            // item.description = 'Empower lives: Donate for prosthetics and wheelchairs.'
          }
          if (item.name === 'Emergency Medical Aid') {
            // item.description = 'Save lives in emergencies: Your donation supports vital medical care.'
          }
          return item;
        });
        let tempProductFirstTwo = temp.products
          .filter((item, index) => index > 2 && Number(item.status) === 1 && (item.id === 394 || item.id === 395))
          .map(item => item);

        let tempProdsRest = temp.products
          .filter((item, index) => index > 2 && Number(item.status) === 1 && item.id !== 394 && item.id !== 395)
          .map(item => item);

        let tempProducts = [...tempProductFirstTwo, ...tempProdsRest];
        temp.products = tempProducts;
        setProducts(temp);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('error========', error);
      setIsLoading(false);
    }
  };
  const handleParagraph = text => {
    let tempText = [];
    if (text) {
      tempText = text.split('.');
      return tempText.map((txt, index) => {
        let temp =
          txt === '' ? (
            ''
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const camelCaseformat = val => {
    let array = val.split('');
    for (let index = 0; index < array.length; index++) {
      if (array[index] === '-') {
        array[index + 1] = array[index + 1].toUpperCase();
      }
    }
    return array.join('').replaceAll('-', '');
  };
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let pathNames = window.location.pathname.split('/');
    if (pathNames.length > 2) {
      let temp = pathNames[2].split('-');
      temp = temp.join(' ');
      getProductsByName(temp);
      let p = camelCaseformat(pathNames[1]);
      let c = camelCaseformat(pathNames[2]);
      setMeta({
        ...meta,
        parent: p,
        child: c,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout>
      {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) && (
        <Helmet>
          <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
        </Helmet>
      )}
      {/* <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]"> */}
      {!isLoading && (
        <img
          src={products.tile_image}
          alt={products.name}
          className="w-full"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/general/logo-replace.png';
            currentTarget.className = 'max-h-[150px] mx-auto';
          }}
        />
      )}
      {/* </div> */}
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            {!isLoading ? products.name : <Skeleton height={80} />}
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {products.products.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency} />
                  </div>
                );
              })}
              <div className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white">
                  <div className="overflow-hidden img-hover-zoom rounded-md w-full sm:h-[169px] md:w-[210px] md:h-[210px] lg:w-[301px] lg:h-[301px] xl:w-[280px] xl:h-[280px] 2xl:w-[344px] 2xl:h-[344px] tooltip [&>span]:!block">
                    {projectNoorCategor ? (
                      projectNoorCategor.image_link ? (
                        <LazyLoadImage
                          alt={projectNoorCategor.name}
                          effect="blur"
                          width={`100%`}
                          delayTime={500}
                          className="rounded cursor-pointer block"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/general/logo-replace.png';
                          }}
                          src={projectNoorCategor.image_link} // use normal <img> attributes as props
                        />
                      ) : (
                        <img className="w-full" src="/images/general/logo-replace.png" alt="" />
                      )
                    ) : (
                      <Skeleton height={344} />
                    )}
                  </div>
                  <h3 className="text-[#00a3da] flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center font-medium h-[42px] sm:h-[40px] lg:h-[56px]  my-2 leading-4 md:leading-none">
                    {projectNoorCategor ? projectNoorCategor.name : <Skeleton height={56} />}
                  </h3>
                  <div className="relative text-center h-[110px]">
                    {projectNoorCategor ? (
                      <>
                        <div className={`transition-all duration-300 text-center my-2 relative overflow-hidden max-h-[65px] md:max-h-[78px] mb-0`}>
                          <p
                            className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] `}
                          >
                            {projectNoorCategor.description}
                          </p>
                        </div>
                        <div className="flex h-8 pb-1"></div>
                      </>
                    ) : (
                      <Skeleton count={4} />
                    )}
                  </div>
                  <div className="grid grid-cols-6 gap-1 2xl:gap-2">
                    {projectNoorCategor ? (
                      <h2 className="text-[#f60362] col-span-6 text-md md:text-xl text-center font-bold h-6 md:h-7">
                        <small className={`uppercase`}> </small>
                      </h2>
                    ) : (
                      <div className="col-span-6">
                        <Skeleton />
                      </div>
                    )}
                    {projectNoorCategor ? (
                      <button
                        className="text-white text-sm md:text-md lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 font-medium flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] cursor-pointer px-2 py-2 justify-center items-center"
                        onClick={() => {
                          navigate('/sadaqah-jariyah/project-noor');
                        }}
                      >
                        View Appeals
                      </button>
                    ) : (
                      <Skeleton height={43} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-span-12 rounded-xl mt-6 lg:min-h-[500px]- bg-white p-6 lg:p-12 text-center shadow-lg ${
                products && (products.name === 'Food Packs') | (products.name === 'Cow Share') ? 'hidden' : ''
              }`}
            >
              <h1 className="text-[#00a3da] text-4xl mb-4">{products ? products.page_title : <Skeleton />}</h1>
              <p className="text-[#777] text-xl mb-4">{products ? products.page_subtitle : <Skeleton />}</p>
              <div className="text-[#999] text-md">
                {products ? (
                  products.page_description && products.page_description.includes('<') ? (
                    <RawHTML>{products.page_description}</RawHTML>
                  ) : (
                    handleParagraph(products.page_description)
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
              <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/Z3DtUoz0scc?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default HealthLandingPage;
