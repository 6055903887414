

const H1Tag = ({ text = '', firstRedText = '', lastRedText = '', className = '' }) => {
    /* ----------------------------- Start variables ---------------------------- */
    /* -------------------------------------------------------------------------- */
    return <h1 className={`${className} w-full mb-2 text-2xl md:text-4xl text-[#093686] font-brandingBold`}>
    {firstRedText && <span className="text-[#F60362] mr-1">{firstRedText}</span>}
    {text}
    {lastRedText && <span className="text-[#F60362] ml-1">{lastRedText}</span>}
    </h1>
};

export default H1Tag;
