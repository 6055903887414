import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import eventBanner from '../../images/events/event-USACanada-banner.jpg';
import { selectProducts } from '../../store/products';

const AliBanatCADLandingPage = ({ speaker }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const [majedMahmoudProducts, setMajedMahmoudProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-WNT-FND-2023-04') tempProductsList[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') tempProductsList[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') tempProductsList[2] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') tempProductsList[3] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-02') tempProductsList[4] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-02') tempProductsList[5] = inner_item_product;
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') tempProductsList[6] = inner_item_product;
          if (inner_item_product.creator === 'RM24-ZM-013') tempProductsList[7] = inner_item_product;
          if (inner_item_product.creator === 'MKD-MN-001') tempProductsList[8] = inner_item_product;
          if (inner_item_product.creator === 'FST-DEL-F150-2023-01-010') tempProductsList[9] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') tempProductsList[10] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') tempProductsList[11] = inner_item_product;
          if (inner_item_product.creator === 'AO08-SPC-2023-01-002') tempProductsList[12] = inner_item_product;

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempProductsList.forEach(e => console.log(e.creator));
    tempProductsList = tempProductsList.filter(n => n);
    setMajedMahmoudProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout>
      <div>
        {
          <img
            src={eventBanner}
            alt={'yasminmogahedmalaysia'}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-5xl md:text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            Plant Your Sadaqah
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {majedMahmoudProducts.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
              <p className="text-[#777] text-xl mb-4">
                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate
                now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AliBanatCADLandingPage;
