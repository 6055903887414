import { Builder } from '@builder.io/react';

const HtmlContent = ({
  htmlCode = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  const RawHTML = ({
    children,
    className = ""
  }) => <div className={className} dangerouslySetInnerHTML={{
    __html: children.replace(/\n/g, '')
  }} />;
  /* -------------------------------------------------------------------------- */
  return <RawHTML>{htmlCode}</RawHTML>;
};
Builder.registerComponent(HtmlContent, {
  name: 'HtmlContent',
  inputs: [{
    "name": "htmlCode",
    "type": "string"
  }]
});
export default HtmlContent;