import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
// import banner from '../../images/landings/palestine/anniversary-landing-banner-gaza.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import { OurPromiseSection } from '../../components/general';
import { impactInfo, impactSliderData, winterVideos as videos } from '../crisis-and-emergencies/fixtures';
import WinterTabs from './winterTabs';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import { winterFaqs } from './faq';
import Accordion from '../../components/general/accordion';
import winterSlider1 from '../../images/landings/winter/Winter-Landing-Page-Image-1.jpg';
import winterSlider2 from '../../images/landings/winter/Winter-Landing-Page-Image-2.jpg';
import winterSlider3 from '../../images/landings/winter/Winter-Landing-Page-Image-3.jpg';
import winterSlider4 from '../../images/landings/winter/Winter-Landing-Page-Image-4.jpg';
import winterSlider5 from '../../images/landings/winter/Winter-Landing-Page-Image-5.jpg';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const WinterLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [winterEmergencyProducts, setWinterEmergencyProducts] = useState([0, 0, 0, 0]);
  const [pakistanWinterEmergencyProducts, setPakistanWinterEmergencyProducts] = useState([0, 0, 0, 0]);
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const contentRef = useRef(null);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  const images = [
    { src: winterSlider2, alt: 'Donate for winter emergency' },
    { src: winterSlider3, alt: 'Donate for winter emergency' },
    { src: winterSlider4, alt: 'Donate for winter emergency' },
    { src: winterSlider5, alt: 'Donate for winter emergency' },
  ];
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempWinterEmergencyProducts = [];
    let tempPakistanWinterEmergencyProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-01') {
            tempWinterEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-2') {
            tempWinterEmergencyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-02') {
            tempWinterEmergencyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-03') {
            tempWinterEmergencyProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-04') {
            tempWinterEmergencyProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-01') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-02') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-03') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-04') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempWinterEmergencyProducts = tempWinterEmergencyProducts.filter(n => n);
    tempPakistanWinterEmergencyProducts = tempPakistanWinterEmergencyProducts.filter(n => n);
    setWinterEmergencyProducts(tempWinterEmergencyProducts);
    setPakistanWinterEmergencyProducts(tempPakistanWinterEmergencyProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['winter']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['winter']['description']}`} />
        </Helmet>
      )}

      <section className="xs:block lg:hidden top-16 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>

      {/* <section className="flex justify-center">
        <img
          src={language === 'fr' ? bannerFr : banner}
          alt="Donate to Palestine"
          className="max-[500px]:hidden w-full"
        />
        <img
          src={language === 'fr' ? bannerMobileFr : banner}
          alt="Donations to Palestine"
          className="min-[501px]:hidden w-full"
        />
      </section> */}
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden lg:block top-16 lg:top-24 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <div ref={contentRef} />
      <WinterTabs winterEmergencyProducts={winterEmergencyProducts} pakistanWinterEmergencyProducts={pakistanWinterEmergencyProducts} lang={lang} />
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}

      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8 flex flex-col">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2
            className={`text-[18px] md:text-4xl ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } text-[#253B7E] text-center font-black`}
          >
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">
              {lang === 'fr' ? 'IMPACT TOTAL:' : lang === 'ar' ? 'التأثير الكلي:' : 'Total Impact:'}
            </span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <div className="hidden md:container md:mx-auto sm:grid sm:grid-cols-4 xl:grid-cols-4 gap-16 justify-between items-center">
          {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
            <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
              <img src={img.src} alt={img.alt} />
              <div className="flex flex-col items-center text-center">
                <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                  {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                </small>
              </div>
            </div>
          ))}
        </div>
        <div className={`sm:hidden px-6 md:px-0 relative`}>
          <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
            {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                  <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                    {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                  </small>
                </div>
              </div>
            ))}
          </div>
          {loadedImpact && instanceRefImpactSlider.current && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                className="bg-transparent"
                disabled={currentImpactSlide === 0}
                icon={impactSliderLeftIcon}
              />

              <Arrow
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                className="bg-transparent"
                icon={impactSliderRightIcon}
                rightMargin
              />
            </>
          )}
        </div>
      </section>
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
            className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${
                lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
            }`}
        >
          <strong className="text-LightBlue">Winter</strong> is a <strong className="text-LightBlue">silent
          killer</strong> and <i>it’s here</i>.
        </h1>
        <p
            className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 text-center ${
                lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
            }`}
        >
          As millions around the world prepare for <strong className="text-LightBlue">end of year holidays</strong>,
          others brace themselves for a <strong className="text-LightBlue">deadly season</strong>. <strong
            className="text-LightBlue">Winter</strong> is descending like a heavy blanket and the <strong
            className="text-LightBlue">cold</strong> is biting. Families who have fled homes and refugees whose camps
          are no longer safe seek refuge where they can, only to find <i>a struggle for survival</i>, dwindling
          resources, and despair.
          <br/>
          <br/>
          In places like <strong className="text-LightBlue">Palestine</strong>, orphans who sleep hungry, now sleep
          shivering.
          <br/>
          <br/>
          In <strong className="text-LightBlue">Lebanon</strong>, the <strong
            className="text-LightBlue">cold</strong> is felt in their bones and without someone to care for them,
          they’re left vulnerable to the elements.
          <br/>
          <br/>
          For families living in poverty in <strong className="text-LightBlue">Pakistan</strong> and <strong
            className="text-LightBlue">Afghanistan</strong>, <strong className="text-LightBlue">winter</strong> will be
          their biggest threat to survival—especially for orphans.
          <br/>
          <br/>
          With nothing to keep them warm, mothers worry about how they’ll feed their babies and their children.
          <br/>
          <br/>
          With each passing day, their hopes sink further, buried beneath layers of frost and fear.
          <br/>
          <br/>
          We can’t control the seasons but we are in control of our actions and we must <i>act now</i> because <strong
            className="text-LightBlue">winter</strong> won’t wait. It can and it will claim lives if we don’t move fast.
          <br/>
          <br/>
          All we need is you. Your <strong className="text-LightBlue">Sadaqah</strong> and your <strong
            className="text-LightBlue">Zakat</strong> will help us save lives.
          <br/>
          <br/>
          Help us urgently bring warmth to our brothers and sisters in <strong
            className="text-LightBlue">Lebanon</strong>. <strong className="text-LightBlue">Donate Now</strong>
        </p>
        <button
            type="submit"
            onClick={() => {
              window.scrollTo({behavior: 'smooth', top: contentRef.current.offsetTop - 220});
            }}
            className={`mt-6 md:mt-8 w-fit flex-col mx-auto rounded-full bg-[#f60362] text-white  focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da] flex items-center justify-center px-6 pt-2 pb-2 font-normal text-xl md:text-2xl transition duration-150 ease-in-out`}
        >
          DONATE NOW
          <span className="text-xs md:text-sm font-brandingLight tracking-tighter">SAVE THEM FROM THE COLD</span>
        </button>
        <p
            className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 text-center ${
                lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
            }`}
        >
          <strong className="text-LightBlue">MATW Project IS ON THE GROUND</strong>
          <br/>
          <br/>
          With warehouses in <strong className="text-LightBlue">Lebanon</strong>, a steady stock supply in <strong
            className="text-LightBlue">Jordan</strong> and other parts of the world, the <strong
            className="text-LightBlue">Muslims Around The World Project</strong> team is committed to implementing our
          biggest winterisation project to date. Our team is on the ground in <strong
            className="text-LightBlue">Palestine</strong>, <strong className="text-LightBlue">Lebanon</strong>, <strong
            className="text-LightBlue">Jordan</strong>, <strong className="text-LightBlue">Turkey</strong>, <strong
            className="text-LightBlue">Pakistan</strong> and <strong className="text-LightBlue">Afghanistan</strong>.
          We’re delivering food, hot meals, water resistant shelter, warm clothing and so much more every day. We won’t
          stop. By the will of Allah, we’re ready to respond.
          <br/>
          <br/>
          Your <strong className="text-LightBlue">Sadaqah</strong> could be the key to their survival this
          winter. <strong className="text-LightBlue">Donate now</strong>.
          <br/>
          <br/>
          Between us and them—our seasons are worlds apart.
          <br/>
          <br/>
          <strong className="text-LightBlue">Donate Now</strong> and help us save lives.
        </p>
        <button
            type="submit"
            onClick={() => {
              window.scrollTo({behavior: 'smooth', top: contentRef.current.offsetTop - 220});
            }}
            className={`mt-6 md:mt-8 w-fit flex-col mx-auto rounded-full bg-[#f60362] text-white  focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da] flex items-center justify-center px-6 pt-2 pb-2 font-normal text-xl md:text-2xl transition duration-150 ease-in-out`}
        >
          DONATE NOW
          <span className="text-xs md:text-sm font-brandingLight tracking-tighter">SAVE THEM FROM THE COLD</span>
        </button>
      </div>

      {/* /* -------------------------------------------------------------------------- */}

      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection images={images}/>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h1 className="text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold">
          Winter Emergency <span className="text-[#F60362]"> FAQ’s</span>
        </h1>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[0].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[1].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[2].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {winterFaqs.map((item, index) => {
            return (
                index > 2 && (
                    <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                      <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1}
                                 noBorder={true}>
                        {item.answer}
                      </Accordion>
                    </div>
                )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default WinterLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
      <div
          className={`${
              props.bg ? props.bg : 'bg-[#F60362]'
          } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
              props.className ? props.className : ''
          } ${props.left ? 'arrow--left left-[8px]' : 'arrow--right left-auto right-[8px]'}`}
          onClick={props.onClick}
      >
        {props.icon ? (
            <img alt="arrow-img" src={props.icon}
                 className={`left-0 relative top-10 ${props.rightMargin ? 'ml-1' : '-ml-1'} `}/>
        ) : (
            <svg
                className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
              {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>}
              {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>}
            </svg>
        )}
      </div>
  );
}
