import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ReactSelect from 'react-select';
import creditImg from '../../images/payment/credit-cards.png';
import SelectCurrency from '../general/selectCurrency';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import CustomButton from '../general/button';
import ShowCurrencies from '../../utils/showCurrencies';

const styles = {
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const WidgetDonateNow = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    selectedCurrency,
    className,
    defaultValue = 250,
    defaultProduct: { creator = 'EMR-LEB-24-000', label = 'Lebanon Emergency Appeal', value = 674 } = {},
    mode = 0, // 0 for 'Single', 1 for 'Monthly' if needed
  } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector((state) => selectProducts(state));
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  const [selectedPrice, setSelectedPrice] = useState(props.selectedPrice || 250);
  const [productAmount, setProductAmount] = useState(selectedPrice ? null : defaultValue);
  const [currentProduct, setCurrentProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });

  // Added for recurring modes
  const suggestedRecurringModes = [
    { value: 'one-off', label: 'Single' },
    { value: 'month', label: 'Monthly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(
      mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]
  );

  const suggestedPrices = [50, 150, 250, 500, 1000, 2000];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];
    products.map((item) => {
      item.sub_categories.map((inner_item) => {
        inner_item.products.map((inner_item_product) => {
          if (inner_item_product.creator === creator) {
            setSelectedProduct(inner_item_product);
          }
          if (inner_item.name === 'Make A Donation') {
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);
    tempImportantProducts = tempImportantProducts.filter((n) => n);
    setProductsList(tempImportantProducts);
    setAllProducts(tempMakeDonationProducts);
  };

  const handleCurrency = (currency) => {
    setCurrentCurrency(currency.value);
    selectedCurrency(currency);
  };

  const handleProduct = (value) => {
    allProducts.map((item) => item.id === value.value && setSelectedProduct(item));
    setCurrentProduct(value);
  };

  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount)
        ? Number(productAmount)
        : Number(selectedPrice)
            ? Number(selectedPrice)
            : 250;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    addProduct({ product: tempProduct, currency: currentCurrency });
  };

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // Load products on component mount
  }, []);

  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
  }, [products]);

  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */

  return (
      <div
          className={`w-full md:w-[360px] md:h-[500px] bg-[#00a3da] p-6 flex flex-col items-center ${className}`}
      >
        {/* Donate Now Header */}
        <h2 className="text-white text-4xl mb-8 mt-2">Donate Now</h2>

        {/* Recurring Modes */}
        <div className="w-full flex justify-center mb-4">
          {suggestedRecurringModes.map((modeOption, index) => (
              <button
                  key={index}
                  className={`flex-1 text-center py-2 rounded-md mx-1 lg:hover:bg-[#F60362] lg:hover:text-white ${
                      selectedRecurring.value === modeOption.value
                          ? 'bg-[#F60362] text-white'
                          : 'bg-white text-[#F60362]'
                  }`}
                  onClick={() => setSelectedRecurring(modeOption)}
              >
                {modeOption.label}
              </button>
          ))}
        </div>

        {/* Product Select */}
        <div className="w-full mb-4">
          <ReactSelect
              options={productsList}
              className="max-h-[48px] [&>div]:!rounded-md"
              classNamePrefix="donate-select"
              value={currentProduct}
              defaultValue={currentProduct}
              isSearchable={false}
              styles={styles}
              onChange={(e) => handleProduct(e)}
          />
        </div>

        {/* Suggested Prices */}
        <div className="w-full grid grid-cols-3 gap-2 mb-4">
          {suggestedPrices.map((price, index) => (
              <div key={`prices${index}`} className="col-span-1">
                <div
                    className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 py-2 rounded-md lg:hover:bg-[#F60362] lg:hover:text-white4 ${
                        selectedPrice === price
                            ? 'bg-[#F60362] text-white'
                            : 'bg-white text-[#F60362]'
                    }`}
                    onClick={() => {
                      setSelectedPrice(price);
                      setProductAmount('');
                    }}
                    style={{ width: '100%' }}
                >
                  {ShowCurrencies(currentCurrency, price, false)}
                  <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span>
                </div>
              </div>
          ))}
        </div>

        {/* Currency Select and Amount Input */}
        <div className="w-full flex items-center mb-4 bg-white rounded-md h-[38px]">
          <div className="min-w-max">
            <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select"/>
          </div>
          <input
              value={productAmount}
              name="amount"
              className="h-[38px] text-[#777] w-full truncate p-2 rounded-lg text-sm pl-1 focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              min={'0'}
              onChange={(e) => {
                const regex = /^(?:[1-9]\d*)?$/;
                if (regex.test(e.target.value)) {
                  setProductAmount(e.target.value);
                  setSelectedPrice(null);
                }
              }}
              placeholder="Amount"
          />
        </div>

        {/* Donate Now Button */}
        <div className="w-full mb-4">
          <CustomButton
              onClick={() => {
                handleAddNewProduct();
              }}
              title={'Donate Now'}
              disabled={!isLoaded}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${isLoaded ? 'blob red' : ''}`}
          />
        </div>

        {/* Payment Methods Image */}
        <div className="w-full bg-[#80ceff] rounded-md px-3 h-[38px] flex items-center justify-center">
          <img
              src={creditImg}
              alt="Payment Methods"
              className="w-full h-auto"
          />
        </div>
      </div>
  );
};

export default WidgetDonateNow;
