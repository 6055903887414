import React, { useEffect, useState } from 'react';
import OrphanCrisisWidget from '../orphans/OrphanCrisesWidget';
import CrisisWidget from './components/crisisWidget';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { CustomButton, OurPromiseSection } from '../../components/general';
import useHandleAddProduct from '../../utils/handleAddProduct';
// import banner from '../../images/landings/countries-crisis/countries-in-crisis-desktop-banner.jpg';
import CountriesCrisisTabs from './components/countries-crisis-tabs';
// import banner from '../../images/landings/lebanon-appeal/lebanon-emergency-banner.jpg';
import banner from '../../images/landings/lebanon-appeal/Leb-Desktop-Banner.jpg';
import bannerMobile from '../../images/landings/lebanon-appeal/Leb-Mobile-Banner.jpg';
import lebanonEmergencyImg from '../../images/landings/lebanon-appeal/MATW---Leb-Square-(1).jpg';
import lebanonOrphanImg from '../../images/landings/lebanon-appeal/MATW---Leb-Square-(2).jpg';
import lebanonWaterImg from '../../images/landings/lebanon-appeal/MATW---Leb-Square-(3).jpg';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const LebanonAppealLandingPage = () => {
  const products = useSelector(state => selectProducts(state));
  const [lebanonAppealProducts, setLebanonAppealProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [lebanonEmergencyProduct, setLebanonEmergencyProduct] = useState();
  // const [lebanonWaterProduct, setLebanonWaterProduct] = useState();
  // const [lebanonOrphanProduct, setLebanonOrphanProduct] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  /* -------------------------------------------------------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let lebanonAppealProductsList = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            lebanonAppealProductsList[0] = inner_item_product;
            setLebanonEmergencyProduct(inner_item_product);
          }
          if (inner_item_product.creator === 'AO-EMR-LEB-24-000') lebanonAppealProductsList[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-LEB-24-007') lebanonAppealProductsList[2] = inner_item_product;
          if (inner_item_product.creator === 'ORP-DIS-SOL-2023-01-035') lebanonAppealProductsList[3] = inner_item_product;
          if (inner_item_product.creator === 'OPR-FUND-LB-2023-01-01') {
            lebanonAppealProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-02') {
            lebanonAppealProductsList[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-03') {
            lebanonAppealProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-04') {
            lebanonAppealProductsList[7] = inner_item_product;
          }
        });
      });
    });

    lebanonAppealProductsList = lebanonAppealProductsList.filter(n => n);
    setLebanonAppealProducts(lebanonAppealProductsList);
  };
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className={`bg-[#fff]`}>
      {seo && (
        <Helmet>
          <title>{seo['lebanon']['title']}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={seo['lebanon']['description']} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'EMR-LEB-24-000',
            label: 'Lebanon Emergency Appeal',
            value: 674,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="w-full max-[501px]:hidden" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'EMR-LEB-24-000',
                label: 'Lebanon Emergency Appeal',
                value: 674,
              }}
            />
          </div>
        </section>
      </section>
      <CountriesCrisisTabs currentProducts={lebanonAppealProducts} />
      <OurPromiseSection childClasses={'!bg-[#fff]'} />
      <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-3 md:py-6">
        <div className="col-span-2 md:col-span-2 order-2 md:order-1">
          <div className="font-brandingMedium text-center text-black text-xl md:text-4xl px-4 w-full">
            Lebanon <strong className="text-LightBlue font-bold">CRISIS</strong> unfolding in front of our eyes.
            <br />
            Countless <span className="italic">men, women and innocent children</span> are in need of{' '}
            <strong className="text-LightBlue font-bold">ASSISTANCE</strong> urgently.
            <br />
            <br />
            Your priceless donations will help us save lives.
          </div>
          <CustomButton
            disabled={!lebanonEmergencyProduct}
            onClicked={() => {
              addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
            }}
            title="DONATE NOW"
            className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit mt-3 md:my-10 !w-fit hover:!bg-[#f60362]"
          />
        </div>
      </section>
      <section className="md:container md:mx-auto px-4 xl:px-10 mt-1 sm:mt-6">
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium">
          A nation already brought to its knees by poverty now faces an <strong className="italic">unthinkable catastrophe.</strong>
          <br />
          Lebanon has been further crippled through conflict.
          {/* <strong>This is the reality in Palestine.</strong> Innocent children and parents losing limbs, their bodies torn apart by the devastation of conflict. <strong>Imagine this happening to your child.</strong> Imagine your loved one suddenly unable to walk, to run, to hold you. */}
          <br />
          <br />
          Over <strong className="text-LightBlue">80% of the population</strong> is engulfed by poverty.
          <br />
          Thousands of <strong className="text-LightBlue">innocent lives</strong> have been lost.
          <br />
          More than a <strong className="text-LightBlue">million</strong> left homeless.
          <br />
          Millions in <strong className="text-LightBlue">urgent need.</strong>
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-6">
          For over a decade Lebanon has been facing a devastating ordeal in which very few nations have experienced. A nation crippled by poverty{' '}
          <strong className="text-LightBlue font-bold">affecting over 80%</strong> of people, taking away their access to life’s basic necessities.
          <br />
          <br />
          But today the situation is even more dire…
          <br />
          <br />
          Conflict has spilled from <strong className="text-LightBlue font-bold">Gaza into Lebanon</strong>, escalating rapidly. The country is now at
          a critical point. More than a million <strong className="text-LightBlue font-bold">Lebanese, Syrians and Palestinians</strong> have fled
          their homes due to the relentless violence. Within a short period of time, thousands have lost their lives including{' '}
          <strong className="italic">many women and children.</strong>
          This terrible disaster has left many families hopeless.
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-6">
          Here at <strong className="text-LightBlue font-bold">MATW Project</strong>, we’ve responded swiftly and are on the ground in Lebanon across
          dozens of areas. We’re delivering lifesaving relief to countless families, providing them with{' '}
          <strong className="text-LightBlue font-bold">medical kits, hot meals, food packs, blankets, fuel and beds</strong>. This vital humanitarian
          effort is relieving the distress of the elderly, widows, orphans and anyone in need of help.
          <br />
          <br />
          Sadly, our efforts can only go so far without your help. We need your continued support now more than ever. The{' '}
          <strong className="text-LightBlue font-bold">situation is critical</strong>, and the window to save lives is rapidly closing.
          <br />
          <br />A <strong className="text-LightBlue font-bold">simple donation</strong> today can be the difference between life and death.
          <br />
          <br />
          Please donate <strong className="text-LightBlue font-bold">urgently</strong> and help us save lives today.
          <br />
          <br />
          We operate on a <strong className="text-LightBlue font-bold">100% Donation Policy</strong> so rest assured,{' '}
          <strong className="text-LightBlue font-bold">100%</strong> of your contributions will reach those in need directly. This project is also{' '}
          <strong className="text-LightBlue font-bold">Zakat</strong> Applicable.
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-8">
          The Lebanese economy was on the brink of collapse for many years as millions were surviving on very little. Towards the end of September,
          the escalating conflict has forced more than a million to flee their homes, and they’re now reliant on humanitarian relief.
          <br />
          <br />
          MATW’s teams are working around the clock, day and night, delivering food, water, medication and shelter to Lebanese, Syrians and
          Palestinians desperate for help.
          <br />
          <br />
          Your donations today can bring respite tomorrow to many men, women and children including widows and orphans.
        </div>
        <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => {
            addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
          }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        />
        <div className="w-full my-2 md:my-4">
          <img src={lebanonEmergencyImg} alt="lebanon emergency" className="w-full rounded-lg" />
        </div>
        {/* <div className="text-black leading-6 text-[18px] text-center font-brandingMedium mt-8 md:mt-16">
          There are tens of thousands of orphans living in Lebanon and many of them are desperately waiting for support,
          as they’ve been forgotten over many years. Over many decades, countless Palestinians settled in Lebanon and over the
          last 15 years, millions of Syrians set up makeshift camps.
          <br />
          <br />
          With Palestinian, Syrian and Lebanese orphans populating various parts of Lebanon and their numbers rising daily, the need for assistance is pressing.
          <br />
          <br />
          MATW’s teams are caring for many orphans and your generous donations will help us to bring them respite and relief, with a better future ahead.
        </div> */}

        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-8 md:mt-16">
          Due to the escalating conflict, more than 1 million people across the southern parts of Lebanon have become displaced. Access to clean and
          safe water is becoming a rarity as relief organisations struggle to distribute to the many people in need.
          <br />
          <br />
          MATW Project’s teams on the ground are hard at work delivering this basic yet ultimately lifesaving necessity.
          <br />
          <br />
          Please donate below to help us assist the many men, women and children who are fighting for survival across many parts of Lebanon.
        </div>
        <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => {
            addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
          }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        />
        <div className="w-full my-2 md:my-4">
          <img src={lebanonOrphanImg} alt="lebanon orphans" className="w-full rounded-lg" />
        </div>
        <div className="font-brandingMedium text-center text-black text-xl md:text-4xl px-4 w-full mt-8 md:mt-16">
          A dual disaster for the <strong className="text-LightBlue font-bold">SYRIAN</strong> people.
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-6">
          Lebanon’s situation is unimaginable: <strong className="text-LightBlue font-bold">poverty has tripled</strong> in the last decade, and now,
          with conflict spilling into their borders, the suffering of its people has reached new depths. But it's not just the Lebanese people who are
          suffering; <strong className="text-LightBlue font-bold">Syrian refugees</strong> who once sought safety in Lebanon are now being forced to
          flee again.
          <br />
          <br />
          More than a million Lebanese have fled their homes due to the relentless violence, with hundreds of thousands of people
          <strong className="italic">—both Lebanese and Syrians—</strong>forced to cross into Syria just to escape the danger.
          <br />
          <br />
          There are still many Syrians seeking shelter across other parts of Lebanon, and for them, their{' '}
          <strong className="text-LightBlue font-bold">situation is even more dire</strong> as they have absolutely nowhere to go, with no family or
          relatives to rely on for assistance.
          <br />
          <br />
          {/* Imagine fleeing your home, leaving everything behind in a desperate attempt to survive. For Syrian refugees,
          Lebanon was once a place of safety, a <strong className='text-LightBlue font-bold'>fragile refuge</strong> after escaping the horrors of war. But now, after years of
          rebuilding their lives from the ashes, they face the <strong className='text-LightBlue font-bold'>unthinkable</strong> once again: fleeing the place they thought was safe.
          <br />
          <br />
          Our teams are catering to their needs urgently, in the form of humanitarian relief which can reduce their agony and remind them that they are part of this ummah too. */}
          <br />
          <br />
          By donating to the <strong className="text-LightBlue font-bold">Lebanon Emergency Appeal</strong>, you can also cater to the many Syrians
          who are calling out for immediate help in this unprecedented catastrophe
        </div>
        <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => {
            addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
          }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        />
        <div className="w-full my-2 md:my-4">
          <img src={lebanonWaterImg} alt="lebanon water" className="w-full rounded-lg" />
        </div>

        {/* <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => { addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency }) }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        /> */}

        <div className="font-brandingMedium text-center text-black text-xl md:text-4xl px-4 w-full mt-8 md:mt-16">
          <strong className="text-LightBlue font-bold">THE CURRENT SITUATION IN LEBANON</strong>
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-6">
          <strong className="text-LightBlue font-bold">DISPLACEMENT:</strong>
          <br />
          More than 1 million people are living in unsafe conditions, with no shelter, no food, and no access to medical care.
          <br />
          <br />
          <strong className="text-LightBlue font-bold">HUNGER:</strong>
          <br />
          Families are struggling to find enough to eat, and the rising cost of living means many will face winter without even basic necessities.
          <br />
          <br />
          <strong className="text-LightBlue font-bold">FEAR & INSECURITY:</strong>
          <br />
          With conflict continuing and no end in sight, those affected live in constant fear for their lives.
          <br />
          <br />
          The Prophet ﷺ said:
          <br />
          <strong className="italic">
            “Whoever alleviates the need of a needy person, Allah will alleviate his needs in this world and the Hereafter.”
          </strong>
          <br />
          [Sahih Muslim]
          <br />
          <br />
          Your donation is not just a fleeting act of kindness; it will echo into eternity. Imagine the Day of Judgement, when those you helped stand
          before Allah, bearing witness to the <strong className="text-LightBlue font-bold">lifesaving relief</strong> you provided. This could be
          your key to His endless Mercy.
          <br />
          <br />
          <strong className="text-LightBlue font-bold">Millions are waiting for your help.</strong>
          <br />
          <br />
          Do not miss this opportunity—to not only ease the suffering of the desperate but also secure your{' '}
          <strong className="text-LightBlue font-bold">eternal</strong> reward.
          <br />
          <br />
          May Allah SWT reward you for your generosity.
        </div>
        <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => {
            addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
          }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        />
      </section>
      <section className="w-full py-8 md:py-8 bg-DarkerBlue mt-8">
        <div className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 py-4 md:py-8">
          <div className="col-span-2">
            <div className="font-brandingMedium text-center text-white px-4 w-full text-[18px] md:text-2xl">
              <h2 className="text-2xl md:text-6xl font-bold mb-2 md:mb-4">WINTER DISASTER</h2>
              Winter in Lebanon can be devastating. As snow begins to fall in the mountainous regions, these communities will be cut off, isolated
              from help, with no way to protect themselves from the elements. Inside the shelters, families are left with nothing but the clothes on
              their backs, as freezing temperatures creep in and hope begins to fade.
              <br />
              <br />
              You can feel the cold, the hunger, the overwhelming fear that you won’t make it through the night. For <strong>children</strong>, the{' '}
              <strong>elderly</strong>, and the <strong>sick</strong>, winter in these war-torn regions is not just difficult—it’s{' '}
              <strong>deadly</strong>.
              <br />
              <br />
              But it doesn’t have to be this way. <strong>MATW</strong> is on the ground, working against the clock to provide lifesaving winter
              relief. Your donations can save lives now.
            </div>
            {/* <CustomButton
              disabled={!lebanonEmergencyProduct}
              onClicked={() => { addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency }) }}
              title="DONATE NOW"
              className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
            /> */}
            <div className="font-brandingMedium text-center text-white px-4 w-full font-bold mt-4">
              We operate on a 100% Donation Policy so rest assured, 100% of your contributions will reach the people of Palestine and Lebanon
              directly. This project is also Zakat Applicable.
            </div>
          </div>
        </div>
      </section>
      <section className="md:container md:mx-auto px-4 xl:px-10 mt-5 sm:mt-12 flex flex-col items-center justify-center text-center">
        <div className="font-brandingMedium text-center text-black text-xl md:text-4xl px-4 w-full mt-8 md:mt-16">
          The <strong className="text-LightBlue font-bold">BENEFITS</strong> of Sadaqah!
        </div>
        <div className="text-black leading-6 text-[18px] md:text-2xl text-center font-brandingMedium mt-4 md:mt-6">
          What’s happening in Lebanon to the Lebanese, Syrians and Palestinians is truly
          <strong className="text-LightBlue font-bold">unprecedented</strong>. The levels of{' '}
          <strong className="italic">hunger, fear, and insecurity</strong> are beyond imagination.
          <br />
          <br />
          Here are <strong className="text-LightBlue font-bold">three</strong> urgent reasons why you should consider{' '}
          <strong className="text-LightBlue font-bold">donating</strong> to MATW’s Lebanon <strong className="italic">Emergency Appeal</strong> —even
          if you’ve already contributed to this <strong className="text-LightBlue font-bold">lifesaving</strong> cause before:
          <br />
          <br />
          <strong className="text-LightBlue font-bold">REWARDS:</strong> By donating today, you can earn amazing rewards for supporting the oppressed
          and needy who urgently require help.
          <br />
          <br />
          <strong className="text-LightBlue font-bold">RELIEF:</strong> Millions are in urgent need of this support. Your generosity can relieve their
          distress and save lives.
          <br />
          <br />
          <strong className="text-LightBlue font-bold">DUAS:</strong> By supporting the needy and destitute, they will be so grateful and will
          remember you in their duas every time they receive help.
          <br />
          <br />
          Give generously tomorrow, save lives tomorrow, insha Allah.
        </div>
        <CustomButton
          disabled={!lebanonEmergencyProduct}
          onClicked={() => {
            addProduct({ product: lebanonEmergencyProduct, currency: selectedCurrency });
          }}
          title="DONATE NOW"
          className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-3 md:my-10 !w-fit hover:!bg-[#f60362]"
        />
      </section>
    </NewHelmetLayout>
  );
};

export default LebanonAppealLandingPage;
