import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import Skeleton from 'react-loading-skeleton';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import SeoData from '../../utils/seo.json';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import ProductBox from '../../components/general/productBox';
import { selectProducts } from '../../store/products';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
// import banner from '../../../public/images/landings/donate-now/donate-now.webp';

const MakeADonationLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [makeDonationCategory, setMakeDonationCategory] = useState();
  const [makeDonationProducts, setMakeDonationProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const meta = {
    parent: 'donateNow',
    child: 'makeADonation',
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-01') tempMakeDonationProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-05') tempMakeDonationProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-06') tempMakeDonationProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-01') tempMakeDonationProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-01') tempMakeDonationProducts[4] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-02') tempMakeDonationProducts[5] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-03') tempMakeDonationProducts[6] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-04') tempMakeDonationProducts[7] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-05') tempMakeDonationProducts[8] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-06') tempMakeDonationProducts[9] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-07') tempMakeDonationProducts[10] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-08') tempMakeDonationProducts[11] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-09') tempMakeDonationProducts[12] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-10') tempMakeDonationProducts[13] = inner_item_product;
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempMakeDonationProducts = tempMakeDonationProducts.filter(n => n);
    setMakeDonationProducts(tempMakeDonationProducts);
  };
  const getMakeDonationCategory = async () => {
    try {
      let response = await services.categoryProductsByName('make a donation');
      setMakeDonationCategory(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleParagraph = text => {
    let tempText = [];
    if (text) {
      tempText = text.split('.');
      return tempText.map((txt, index) => {
        let temp =
          txt === '' ? (
            ''
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    window.scroll(0, 0);
    getMakeDonationCategory();
  }, []);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout>
      {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) && (
        <Helmet>
          <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
        </Helmet>
      )}
      <img
        src={'/images/landings/donate-now/donate-now.webp'}
        alt={'Make a donation'}
        className="w-full"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/general/logo-replace.png';
          currentTarget.className = 'max-h-[150px] mx-auto';
        }}
      />
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center md:py-8- font-['Gotcha'] h-[125px]">
            Make A Donation
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {makeDonationProducts.map((item, index) => {
                return (
                  <div key={`products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">{makeDonationCategory ? makeDonationCategory.page_title : <Skeleton />}</h1>
              <p className="text-[#777] text-xl mb-4">{makeDonationCategory ? makeDonationCategory.page_subtitle : <Skeleton />}</p>
              <div className="text-[#999] text-md">
                {makeDonationCategory ? (
                  makeDonationCategory.page_description && makeDonationCategory.page_description.includes('<') ? (
                    <RawHTML>{makeDonationCategory.page_description}</RawHTML>
                  ) : (
                    handleParagraph(makeDonationCategory.page_description)
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
              <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/tC_L9aTE8eI?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default MakeADonationLandingPage;
