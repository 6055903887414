import React from 'react';

export const DonateCard = ({ mainPhoto, title, description }) => {
  return (
    <div className="relative lg:max-w-[270px] font-montserrat bg-white rounded-2xl p-5 shadow-[0px_7px_96px_0px_rgba(0,0,0,0.2)]">
      <img {...mainPhoto} className="border-[3px] border-white shadow-[0_2px_5px_2px_rgba(0,0,0,0.4)] -mt-[80px]" />
      <div className="flex flex-col gap-4 items-center justify-center">
        <p className="text-[#474747] text-center text-[26px] uppercase mt-3 font-semibold">{title}</p>
        <div className="flex items-center justify-center gap-1">
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
          <div className="w-2 h-2 rounded-full bg-[#1ed4e0]"></div>
        </div>
        <p className="text-center text-lg text-[#474747]">{description}</p>
      </div>
    </div>
  );
};
