import { Builder } from '@builder.io/react';

import H1Tag from "../../../../general/h1Tag";
import H2Tag from "../../../../general/h2Tag";
const ParagraphTag = ({
  text = '',
  h1 = '',
  h2 = '',
  className = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return <>
            {h1 && <H1Tag text={h1} />}
            {h2 && <H2Tag text={h2} />}
            <br />
            <p className={`${className} text-[#78716C] md:text-lg space-y-4 font-brandingMedium w-full mb-2`}>
                {text}
            </p>
        </>;
};
Builder.registerComponent(ParagraphTag, {
  name: 'ParagraphTag',
  inputs: [{
    "name": "text",
    "type": "string"
  }, {
    "name": "h1",
    "type": "string"
  }, {
    "name": "h2",
    "type": "string"
  }, {
    "name": "className",
    "type": "string"
  }]
});
export default ParagraphTag;