import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { Helmet } from 'react-helmet-new';
import WidgetHome from '../../components/widgets/widgetHomeNew2';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import staticImages from '../../constants/staticImages';
import { ButtonDonateRounded } from './components/buttonDonateRounded';
import { EmergencyAppeal } from './components/emergencyAppeal';
import { DonateCard } from './components/donateCard';
import { donateCardsData } from '../../seeds/homePageData';
import scrollToTop from '../../hooks/general/useScrollToTop';
import { FooterBanner } from '../../components/general/footerBanner';

const HomePageUATNew = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  // const subProducts = useSelector(state => getProducts(state, 'Donate Now', 'Make A Donation'));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      #zsiq_float {
        display: none !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, products]);

  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout
      hideDonateNowButton={true}
      highlights={false}
      className="bg-white"
      childClasses="pb-0"
      isRamadanPage
      hideFooter={true}
      hideMainFooter={true}
      isRelative={true}
    >
      {seo && (
        <Helmet>
          <title>{`${seo['donateToPalestineGhaza']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['donateToPalestineGhaza']['description']}`} />
        </Helmet>
      )}
      <div className="lg:hidden">
        <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} className="!w-full" />
      </div>
      <MaxWidthContainer className="mt-6 !max-w-[1520px]">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="hidden lg:block flex-1 lg:flex-[0_0_450px] bg-[#00a3da]">
            <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} className="!w-full" />
          </div>
          <div className="flex-1 bg-[#eaf7ff]">
            <img {...staticImages.bannerImage} className="w-full" />
            <div className="flex flex-col gap-2 p-4">
              <p className="hidden sm:block text-3xl home-main-title font-inter font-extrabold">
                They have lost everything, <span className="text-[#F60362]"> can you help?</span>
              </p>
              <p className="sm:hidden text-2xl font-inter font-extrabold">
                They have lost everything,{' '}
                <span className="text-[#F60362]">
                  <br />
                  can you help?
                </span>
              </p>
              <p className="lg:text-lg font-inter text-[#333333] leading-[26px]">
                Your donation can help them with food, water and essentials to make their lives easier. Please donate even $1 today. Every amount
                matters. MATW Project is on the ground and making a difference today.
              </p>
            </div>
          </div>
        </div>
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <div className="">
          <img {...staticImages.northernGhaza} className="max-w-[800px] w-full mx-auto" />
          <div className="">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
              <span className="text-[#00a3da] font-medium">400,000</span> are at risk of serious starvation and ultimately death, in the{' '}
              <span className="text-[#00a3da] font-medium">northern part of GAZA</span> due to an unrelenting siege.{' '}
              <span className="text-[#00a3da] font-medium">MATW</span> are one of the only charities still operating here! Your response is required{' '}
              <span className="text-[#00a3da] font-medium">URGENTLY</span> so lives can be saved immediately.
            </p>
          </div>
          <div className="flex justify-center items-center mt-8 md:mt-12">
            <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
          </div>
          <div className="mt-12 col-span-12 h-[300px] md:h-[400px] lg:h-[600px]">
            <iframe
              className="video rounded"
              width={'100%'}
              height={'100%'}
              title="Gifted with Cancer - Ali Banat"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://www.youtube.com/embed/ortsueehefA`}
            ></iframe>
          </div>
        </div>
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <div className="flex flex-col gap-6">
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            One year on, <span className="text-[#00a3da] font-medium">GAZA</span> reels in pain as it continues to face unrelenting and catastrophic
            assaults from land, air and sea.
          </p>
          <div className="flex flex-col">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              <span className="text-[#00a3da] font-medium">TENS of THOUSANDS</span> dead.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              More than <span className="text-[#00a3da] font-medium">100,000</span> are now injured.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              <span className="text-[#00a3da] font-medium">2.3 million</span> face hunger, thirst and agony.
            </p>
          </div>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            <span className="text-[#00a3da] font-medium">MATW’s teams</span> have been responding every single day since the{' '}
            <span className="text-[#00a3da] font-medium">8th of October 2023</span> and we’ve delivered hundreds of trucks with tonnes of relief which
            has relieved the distress of tens of thousands of families in need.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            Thanks to your generosity over the last year, we’ve made an impact of{' '}
            <span className="text-[#00a3da] font-medium">nearly 60 million</span> and this number continues to rise by the day, with your continuous
            support.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            Sadly, the need is so <span className="text-[#00a3da] font-medium">IMMENSE</span> that our relief work is just a drop in the ocean. We
            urgently request your <span className="text-[#00a3da] font-medium">HELP</span> once again.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            A simple donation today can <span className="text-[#00a3da] font-medium">be the difference</span> between life and death.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            Please donate <span className="text-[#00a3da] font-medium">£500, £250, £100</span> or whatever you can afford as this assistance will go a
            long way in relieving the pain and distress of orphans, widows and the elderly.
          </p>
        </div>
        <div className="flex justify-center items-center mt-8 md:mt-12">
          <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
        </div>
        <div className="mt-12">
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">Prophet Muhammad ﷺ said:</p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
            <span className="text-[#00a3da] font-semibold">“Sadaqah is due on every joint of a person, every day the sun rises.”</span>
            <br />
            <span className="text-[#00a3da] font-medium">[Muslim] </span>
          </p>
        </div>
        <div className="mt-12 col-span-12 h-[300px] md:h-[400px] lg:h-[600px]">
          <iframe
            className="video rounded"
            width={'100%'}
            height={'100%'}
            title="Gifted with Cancer - Ali Banat"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://www.youtube.com/embed/mJWZPmuBws0`}
          ></iframe>
        </div>
        <p className="mt-6 text-2xl md:text-4xl leading-[1.6] text-[#707886] text-center font-montserrat">
          <span className="text-[#00a3da] font-medium">WATCH VIDEO:</span> <i>Palestinians Need Us Urgently</i>
        </p>
        <div className="flex justify-center items-center mt-6">
          <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
        </div>
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
          We operate on a <span className="text-[#00a3da] font-medium">100% Donation Policy</span> 100% Donation Policy,{' '}
          <span className="text-[#00a3da] font-medium">100%</span> of your contributions will reach the Palestinian people directly. This project is
          also <span className="text-[#00a3da] font-medium">Zakat</span> Applicable.
        </p>
        <img {...staticImages.homeDonationPolicy} className="mt-12" />
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <div className="">
          <img {...staticImages.palestinianInPain} className="max-w-[800px] w-full mx-auto" />
          <div className="flex flex-col gap-6">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Men, women and children are <span className="text-[#00a3da] font-medium">perishing</span> in Palestine every single day, due to the war,
              starvation, thirst, disease and the cold. This is a calamity beyond imagination and if we don't continue to{' '}
              <span className="text-[#00a3da] font-medium">respond urgently</span> to this disaster, many more will sadly die.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Please donate <span className="text-[#00a3da] font-medium">£500, £250, £100, £50</span> or whatever you can afford to our{' '}
              <span className="text-[#00a3da] font-medium">Palestine Emergency Appeal</span> so we can continue to deliver medical kits, food packs,
              hot meals and fuel to the displaced Palestinians in need.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Palestinians desperately await for food deliveries so they can satisfy their hunger. The rewards for feeding the{' '}
              <span className="text-[#00a3da] font-medium">needy and oppressed </span> or whatever you can afford to our{' '}
              <span className="text-[#00a3da] font-medium">Palestine Emergency Appeal</span> fasting people are truly immense.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Your <span className="text-[#00a3da] font-medium">Sadaqah & Zakah </span> can support many families in need, by satisfying their hunger,
              quenching their thirst, relieving their distress and giving them hope after Ramadan. In the process, your scales of good deeds will
              overflow with <span className="text-[#00a3da] font-medium">beautiful rewards</span> from the Almighty Allah SWT, the one who blessed you
              with all that you own.
            </p>
          </div>
          <div className="flex justify-center items-center mt-8 md:mt-12">
            <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
          </div>
          <div className="mt-8 flex justify-center items-center">
            <img {...staticImages.boyWithBread} className="max-w-[1000px] w-full mx-auto" />
          </div>
        </div>
      </MaxWidthContainer>
      <MaxWidthContainer>
        <EmergencyAppeal onClick={scrollToTop} />
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <div className="">
          <img {...staticImages.whyDonate} className="max-w-[700px] w-full mx-auto" />
          <div className="flex flex-col gap-6 mt-6">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              What's happening in <span className="text-[#00a3da] font-medium">Palestine</span> is truly unprecedented. It is one of the worst
              disasters we've ever come across. The levels of hunger, thirst and displacement are truly unimaginable.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              We're so blessed to be able to <span className="text-[#00a3da] font-medium">eat</span> whatever we want every single day. The
              Palestinians are sadly going many days without food. We're so blessed to be
              <span className="text-[#00a3da] font-medium"> kept warm</span> every night. The Palestinians are shivering due to bone-chilling
              temperatures in the night. We're so blessed to live in <span className="text-[#00a3da] font-medium">safety and security</span>. The
              Palestinians on the other hand fear for their lives every single day.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Putting our blessings aside, here are three reasons why you should consider making a donation to
              <span className="text-[#00a3da] font-medium"> MATW's lifesaving Emergency Appeal for Palestine</span>, even if you have previously
              contributed to this vital cause.
            </p>
          </div>
          <div className="mt-24">
            <div className="grid lg:grid-cols-3 gap-[calc(96px)] lg:gap-6 place-items-center lg:max-w-[940px] mx-auto">
              {donateCardsData.map(({ id, mainPhoto, title, description }) => (
                <DonateCard key={id} mainPhoto={mainPhoto} title={title} description={description} />
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center mt-8 md:mt-12">
            <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
          </div>
        </div>
      </MaxWidthContainer>

      <MaxWidthContainer className="mt-8 md:mt-12">
        <div className="">
          <img {...staticImages.ninetyMillions} onClick={scrollToTop} className="cursor-pointer max-w-[1010px] w-full mx-auto" />
          <div className="flex flex-col gap-6 mt-8">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              What's happening in <span className="text-[#00a3da] font-medium">Palestine</span> is truly unprecedented. It is one of the worst
              disasters we've ever come across. The levels of hunger, thirst and displacement are truly unimaginable.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              We're so blessed to be able to <span className="text-[#00a3da] font-medium">eat</span> whatever we want every single day. The
              Palestinians are sadly going many days without food. We're so blessed to be
              <span className="text-[#00a3da] font-medium"> kept warm</span> every night. The Palestinians are shivering due to bone-chilling
              temperatures in the night. We're so blessed to live in <span className="text-[#00a3da] font-medium">safety and security</span>. The
              Palestinians on the other hand fear for their lives every single day.
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-montserrat">
              Putting our blessings aside, here are three reasons why you should consider making a donation to
              <span className="text-[#00a3da] font-medium"> MATW's lifesaving Emergency Appeal for Palestine</span>, even if you have previously
              contributed to this vital cause.
            </p>

            <div className="flex justify-center items-center">
              <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={scrollToTop} />
            </div>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#333] text-center font-montserrat italic">
              We pray for the Palestinians to one day live a prosperous life full of peace, smiles and happiness. We ask you to pray for their safety
              and security, and to pray for our teams worldwide who are tasked with delivering your trusted donations to the recipients and
              beneficiaries who need it the most.
            </p>
            <img {...staticImages.prayImage} className="max-w-[1000px] w-full mx-auto" />
          </div>
        </div>
      </MaxWidthContainer>

      <div className="mt-8 md:mt-12">
        <FooterBanner />
      </div>
      {/* -------------------------------------Impact------------------------------- */}
    </NewHelmetLayout>
  );
};

export default HomePageUATNew;
